import React, { useState } from "react";
import { Container, Paper, Grid, Skeleton, Typography } from "@mui/material";
import * as casestudyService from "../../services/CasestudyService";
import Notification from "../../../../components/mui/Notification";
import EditableReviewCard from "./EditableReviewCard";
import LoadingButton from "@mui/lab/LoadingButton";
import GroupedCheckbox from "../../../../components/mui/GroupedCheckbox";
import AssistantIcon from "@mui/icons-material/AutoAwesome";
import SaveLoadingButton from "../../../../components/SaveLoadingButton";

const evaluationItems = [
  "Analytical Thinking",
  "Knowledge",
  "Critical Thinking and Problem solving",
  "Writing and Grammer",
  "Creativity",
];

const Questions = ({ course, formValues }) => {
  const [questions, setQuestions] = useState([]);
  const [evaluations, setEvaluations] = useState([]);
  const companyValues = formValues.company;
  const caseStudyId = formValues.caseStudyId;
  const concept = formValues.concept;
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [questionsSaving, setQuestionsSaving] = useState();
  const [questionsSaved, setQuestionsSaved] = useState();
  const [questionsChanged, setQuestionsChanged] = useState();
  const [questionsLoading, setQuestionsLoading] = useState(false);

  const onListItemChange = (id, value) => {
    const updatedList = questions.map((item) =>
      item.id === id ? { ...item, value } : item
    );
    setQuestions(updatedList);
  };

  const toggleListItem = (id, value) => {
    const updatedList = questions.map((item) =>
      item.id === id ? { ...item, isActive: value } : item
    );
    setQuestions(updatedList);
  };

  const applyQuestionChanges = () => {
    setQuestionsSaving(true);
    const request = {
      caseStudyId: formValues.caseStudyId,
      list: questions,
    };
    casestudyService
      .updateQuestions(request)
      .then((ignored) => setQuestionsChanged(false))
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message: "Could not update questions. Please try again later",
          type: "error",
        });
      })
      .finally(() => {
        setTimeout(() => {
          setQuestionsSaving(false);
          setQuestionsSaved(true);
        }, 1000);
        setTimeout(() => {
          setQuestionsSaved(false);
        }, 4000);
      });
  };

  const setCaseStudyQuestions = (list) => {
    const newList = list.map((item, index) => ({
      id: item.id,
      value: item.text,
      isActive: true,
    }));
    setQuestions(newList);
  };

  const handleEvaluationsChange = (selectedItems) => {
    setEvaluations(selectedItems);
  };

  const getQuestions = () => {
    setQuestionsLoading(true);
    const request = {
      caseStudyId: caseStudyId,
      concept: concept,
      studentLevel: course.studentLevel,
      field: course.field,
      companyName: companyValues.names[0],
      evaluationsCriteria: evaluations?.join(", "),
    };

    casestudyService
      .getQuestions(request)
      .then((res) => {
        setCaseStudyQuestions(res.data);
      })
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message: "Could not fetch questions. Please try again later",
          type: "error",
        });
      })
      .finally(() => setQuestionsLoading(false));
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper
        variant="outlined"
        sx={{ my: { xs: 1, md: 2 }, p: { xs: 2, md: 3 } }}
      >
        <React.Fragment>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper
                variant="outlined"
                sx={{ my: { xs: 3, md: 3 }, p: { xs: 2, md: 3 } }}
              >
                <React.Fragment>
                  <Typography
                    sx={{ fontWeight: "bold" }}
                    variant="h5"
                    gutterBottom
                  >
                    Evaluation
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <GroupedCheckbox
                        items={evaluationItems}
                        selectedItems={evaluations}
                        requiredSelectionItems={1}
                        onSelectionChange={handleEvaluationsChange}
                      />
                    </Grid>
                  </Grid>
                </React.Fragment>
              </Paper>
              <Paper
                variant="outlined"
                sx={{ my: { xs: 1, md: 2 }, p: { xs: 2, md: 3 } }}
              >
                <Grid container spacing={0}>
                  <Grid item>
                    <Typography
                      sx={{ fontWeight: "bold" }}
                      variant="h6"
                      gutterBottom
                    >
                      Questions
                    </Typography>
                  </Grid>
                  <Grid item style={{ marginLeft: "auto" }}>
                    <LoadingButton
                      disabled={evaluations.length === 0 || questions.length > 0}
                      sx={{ mr: 1 }}
                      size="small"
                      onClick={() => getQuestions()}
                      loading={questionsLoading}
                      variant="outlined"
                      startIcon={<AssistantIcon />}
                      loadingPosition="start"
                    >
                      <span>Assistant</span>
                    </LoadingButton>
                    <SaveLoadingButton
                      isDisabled={!questionsChanged}
                      handleClick={applyQuestionChanges}
                      isLoading={questionsSaving}
                      isSaved={questionsSaved}
                    />
                  </Grid>
                </Grid>
                {questionsLoading ? (
                  <>
                    <Skeleton variant="rounded" height={60} />
                    <br />
                    <Skeleton variant="rounded" height={60} />{" "}
                  </>
                ) : (
                  questions.map((item, index) => (
                    <Grid item xs={12} key={index}>
                      <EditableReviewCard
                        key={index}
                        content={item.value}
                        isActive={item.isActive}
                        onChange={(e) => {
                          setQuestionsChanged(true);
                          onListItemChange(item.id, e.target.value);
                        }}
                        toggle={(value) => {
                          setQuestionsChanged(true);
                          toggleListItem(item.id, value);
                        }}
                      />
                    </Grid>
                  ))
                )}
              </Paper>
            </Grid>
          </Grid>
        </React.Fragment>
      </Paper>

      <Notification
        notify={notify}
        setNotify={setNotify}
        autoHideDuration={4000}
      />
    </Container>
  );
};

export default Questions;
