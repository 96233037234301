export const isPasswordValid = (password) => {
  // Check if password length is at least 8 characters
  if (password.length < 8) {
    return false;
  }

  // Check if password contains at least one number
  let hasNumber = /\d/.test(password);

  return hasNumber;
};
