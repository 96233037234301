import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import { MuiButton } from "../../../../components";
import { INSTRUCTOR_BOARD_ROUTE } from "../../helper/Constants";
import PageHeader from "../../../../components/PageHeader";
import { useNavigate } from "react-router-dom";
import FileCopyIcon from "@mui/icons-material/FileCopy";

const Completion = () => {
  const navigate = useNavigate();
  const [clientSecret, setClientSecret] = useState();

  const handlePrint = () => {
    window.print();
  };

  const handleCopy = () => {
    // Copy the reference number to the clipboard
    navigator.clipboard.writeText(clientSecret);
  };

  useEffect(() => {
    const paymentIntentClientSecret = new URLSearchParams(
      window.location.search
    ).get("payment_intent_client_secret");

    if (!paymentIntentClientSecret) {
      return;
    } else setClientSecret(paymentIntentClientSecret);
  }, []);

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <PageHeader title={"Successful Payment"} />
      <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Please keep the payment details
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Reference Number
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              {clientSecret}
              <IconButton color="info" onClick={handleCopy}>
                <FileCopyIcon />
              </IconButton>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <Grid item xs={4} sm={8} md={8} sx={{ mt: 2 }} align="center">
        <Stack
          spacing={{ xs: 1, sm: 2 }}
          direction={{ xs: "column", sm: "row" }}
          sx={{ ml: 1 }}
        >
          <MuiButton text="Print" color="success" onClick={handlePrint} />

          <MuiButton
            text="Back to Home page"
            color="info"
            variant="outlined"
            onClick={() => navigate(INSTRUCTOR_BOARD_ROUTE)}
          />
        </Stack>
      </Grid>
    </Container>
  );
};

export default Completion;
