import { BizFields } from ".";
import { isEpmty } from "../../../../../helper/TextHelper";

export const updateValues = (name, value, setState) => {
  setState((prevValues) => ({
    ...prevValues,
    biz: {
      ...prevValues.biz,
      [name]: value,
    },
  }));
};

export const handleInputChanges = (e, setState) => {
  const { name, value } = e.target;
  updateValues(name, value, setState);
};

export const validate = (values) => {
  const errors = {};
  const data = values.biz;
  BizFields.forEach(({ name, required }) => {
    if (required && isEpmty(data[name])) {
      errors[name] = "This field is required.";
    }
  });

  // Add errors to data object
  data.errors = errors;

  // You can add more validation checks here based on your requirements

  // Optionally, you can log data with errors
  console.log("business env data with errors:", data);

  return Object.keys(errors).length === 0;
};

export const createObjectFromFields = () => {
  const obj = {};

  BizFields.forEach(({ name, defaultValue }) => {
    obj[name] = defaultValue;
  });

  return obj;
};
