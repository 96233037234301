import React, { useState } from "react";
import { TextField, Typography } from "@mui/material";

const EditableTextField = ({ value, onChange, ...typographyProps }) => {
  const [focused, setFocused] = useState(false);

  // const containerStyle = {
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  // };

  return (
    // <div style={containerStyle}>
    <div>
      {!focused ? (
        <Typography
          onClick={() => {
            setFocused(true);
          }}
          {...typographyProps}
        >
          {value}
        </Typography>
      ) : (
        <TextField
          fullWidth
          autoFocus
          value={value}
          onChange={onChange}
          onBlur={(event) => setFocused(false)}
        />
      )}
    </div>
  );
};

export default EditableTextField;
