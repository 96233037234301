import React, { useState } from "react";
import { Container, Paper, Grid, Typography } from "@mui/material";
import Notification from "../../../../components/mui/Notification";
import { useLocation, useNavigate } from "react-router-dom";
import { MuiButton } from "../../../../components";
import PageHeader from "../../../../components/PageHeader";
import ViewCard from "./components/ViewCard";

const View = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [values] = useState(state.caseStudy);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <PageHeader title="Case Study Details" isChild={true} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" align="center">
            {values.content.title}
          </Typography>
        </Grid>
      </Grid>

      <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <React.Fragment>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ViewCard
                headerTitle={"Introduction"}
                content={values.content.introduction}
              />
            </Grid>

            {values.content.industryDescription ? (
              <Grid item xs={12}>
                <ViewCard
                  headerTitle={"Industry Description"}
                  content={values.content.industryDescription}
                />
              </Grid>
            ) : null}

            {values.content.industryTrends ? (
              <Grid item xs={12}>
                <ViewCard
                  headerTitle={"Industry Trends and Market Dynamics"}
                  content={values.content.industryTrends}
                />
              </Grid>
            ) : null}

            {values.content.companyHistory ? (
              <Grid item xs={12}>
                <ViewCard
                  headerTitle={"Company History"}
                  content={values.content.companyHistory}
                />
              </Grid>
            ) : null}

            {values.content.competitiveAdvantage ? (
              <Grid item xs={12}>
                <ViewCard
                  headerTitle={"Company Competitive Advantages"}
                  content={values.content.competitiveAdvantage}
                />
              </Grid>
            ) : null}

            {values.content.marketSegment ? (
              <Grid item xs={12}>
                <ViewCard
                  headerTitle={"Company Market Segment"}
                  content={values.content.marketSegment}
                />
              </Grid>
            ) : null}

            {values.content.brands ? (
              <Grid item xs={12}>
                <Paper variant="outlined" sx={{ p: { xs: 2, md: 3 } }}>
                  <Typography variant="h6" gutterBottom>
                    Brands
                  </Typography>
                  {values.content.brands?.map((item, index) => (
                    <Grid item xs={12} sx={{ m: 2 }} key={index}>
                      <Typography>{index + 1 + "- " + item}</Typography>
                    </Grid>
                  ))}
                </Paper>
              </Grid>
            ) : null}

            {values.content.productAndServices ? (
              <Grid item xs={12}>
                <Paper variant="outlined" sx={{ p: { xs: 2, md: 3 } }}>
                  <Typography variant="h6" gutterBottom>
                    Product and Services
                  </Typography>
                  {values.content.productAndServices?.map((item, index) => (
                    <Grid item xs={12} sx={{ m: 2 }} key={index}>
                      <Typography>{index + 1 + "- " + item}</Typography>
                    </Grid>
                  ))}
                </Paper>
              </Grid>
            ) : null}

            {values.content.competitiveAnalysis ? (
              <Grid item xs={12}>
                <ViewCard
                  headerTitle={"Competitive Analysis"}
                  content={values.content.competitiveAnalysis}
                />
              </Grid>
            ) : null}

            {values.content.macroAnalysis ? (
              <Grid item xs={12}>
                <ViewCard
                  headerTitle={"Macro Analysis"}
                  content={values.content.macroAnalysis}
                />
              </Grid>
            ) : null}

            {values.content.microAnalysis ? (
              <Grid item xs={12}>
                <ViewCard
                  headerTitle={"Micro Analysis"}
                  content={values.content.microAnalysis}
                />
              </Grid>
            ) : null}

            {values.content.problemStory ? (
              <Grid item xs={12}>
                <ViewCard
                  headerTitle={"Problem Story"}
                  content={values.content.problemStory}
                />
              </Grid>
            ) : null}

            {values.content.problemTheory ? (
              <Grid item xs={12}>
                <ViewCard
                  headerTitle={"Problem Theoretical Farmework"}
                  content={values.content.problemTheory}
                />
              </Grid>
            ) : null}

            {values.content.questions ? (
              <Grid item xs={12}>
                <Paper variant="outlined" sx={{ p: { xs: 2, md: 3 } }}>
                  <Typography variant="h6" gutterBottom>
                    Questions
                  </Typography>
                  {values.content.questions?.map((item, index) => (
                    <Grid item xs={12} sx={{ m: 2 }} key={index}>
                      <Typography>{index + 1 + "- " + item}</Typography>
                    </Grid>
                  ))}
                </Paper>
              </Grid>
            ) : null}
          </Grid>
        </React.Fragment>
      </Paper>
      <Grid item xs={12} sx={{ mt: 0 }} align="center">
        <MuiButton
          text="Back"
          color="info"
          variant="outlined"
          onClick={() => {
            navigate(-1);
          }}
        />
      </Grid>

      <Notification
        notify={notify}
        setNotify={setNotify}
        autoHideDuration={4000}
      />
    </Container>
  );
};

export default View;
