import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import PageHeader from "../../../../../components/PageHeader";
import * as studentService from "../../../services/AssignmentStudentService";
import { useLocation, useNavigate } from "react-router-dom";
import Notification from "../../../../../components/mui/Notification";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import { LoadingButton } from "@mui/lab";
import ConfirmDialog from "../../../../../components/ConfirmDialog";
import DeleteIcon from "@mui/icons-material/Delete";

export default function Feedback() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState();
  const [records, setRecords] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const firstName = state.firstName;
  const lastName = state.lastName;
  const groupId = state.groupId;
  const feedbackReqIds = state.feedbackReqIds;
  const mode = state.mode;

  useEffect(() => {
    studentService
      .getFeedbacks(groupId, feedbackReqIds, mode)
      .then((response) => {
        setRecords(response.data);
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not connect to server. Please try again later.",
          type: "error",
        });
      })
      .finally(() => setLoading(false));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const send = () => {
    setSubmitting(true);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    const request = records.map(({ question, answer, ...rest }) => rest);
    studentService
      .sendFeedback(groupId, request)
      .then(() => {
        setNotify({
          isOpen: true,
          message: "Confirmed Successfully",
          type: "success",
        });
        navigate(-1);
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not confirm. Please try again later.",
          type: "error",
        });
      })
      .finally(() => setSubmitting(false));
  };

  const handleConfirmation = () => {
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to approve all feedbacks?",
      subTitle:
        "You can't undo this operation. Email will be sent immediately to the student(s)",
      onConfirm: () => {
        send();
      },
    });
  };

  const handleFeedbackChange = (feedbackReqId, feedbackId, field, value) => {
    setRecords((prevRecords) =>
      prevRecords.map((record) =>
        record.reqId === feedbackReqId
          ? {
              ...record,
              feedbacks: record.feedbacks.map((feedback) =>
                feedback.id === feedbackId
                  ? { ...feedback, [field]: value }
                  : feedback
              ),
            }
          : record
      )
    );
  };

  const handleDelete = (feedbackReqId, feedbackId) => {
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this record?",
      subTitle: "You can't undo this operation",
      onConfirm: () => {
        deleteFeedback(feedbackReqId, feedbackId);
      },
    });
  };

  const deleteFeedback = (feedbackReqId, feedbackId) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    studentService
      .deleteFeedback(feedbackId)
      .then((res) => {
        setRecords((prevRecords) =>
          prevRecords.map((record) =>
            record.reqId === feedbackReqId
              ? {
                  ...record,
                  feedbacks: record.feedbacks.filter(
                    (item) => item.id !== feedbackId
                  ),
                }
              : record
          )
        );
        setNotify({
          isOpen: true,
          message: "Deleted Successfully",
          type: "success",
        });
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not delete a feedback. Please try again later.",
          type: "error",
        });
      });
  };

  function isConfirmed(status) {
    return "CONFIRMED" === status;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <PageHeader
        title={firstName + " " + lastName + " 's Feedback"}
        isChild={true}
      />
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Paper variant="outlined" sx={{ my: { xs: 3 }, p: { xs: 2, md: 3 } }}>
            {records.map((record, rIndex) => (
              <Card sx={{ padding: 1, mb: 2 }} key={rIndex}>
                <CardHeader
                  title={<Typography variant="h6">{"Question"}</Typography>}
                  subheader={record.question}
                />
                <CardContent>
                  <Typography variant="h6">{"Answer"}</Typography>
                  <Typography variant="body2">{record.answer}</Typography>

                  <Typography variant="h6" sx={{ mt: 4 }}>
                    {"Feedbacks"}
                  </Typography>
                  <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                    {record.feedbacks.map((feedback, fIndex) => (
                      <>
                        <ListItem
                          key={fIndex}
                          alignItems="flex-start"
                          secondaryAction={
                            !isConfirmed(record.reqStatus) && (
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                color="error"
                                onClick={() =>
                                  handleDelete(record.reqId, feedback.id)
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            )
                          }
                        >
                          <ListItemText
                            primary={
                              <React.Fragment>
                                {!isConfirmed(record.reqStatus) ? (
                                  <TextField
                                    fullWidth
                                    label="Title"
                                    value={feedback.title}
                                    sx={{
                                      "& .MuiInputBase-input": {
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      },
                                      mb: 2,
                                    }}
                                    onChange={(e) =>
                                      handleFeedbackChange(
                                        record.reqId,
                                        feedback.id,
                                        "title",
                                        e.target.value
                                      )
                                    }
                                  />
                                ) : (
                                  <Typography variant="body1">
                                    {feedback.title}
                                  </Typography>
                                )}
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                {!isConfirmed(record.reqStatus) ? (
                                  <TextField
                                    label="Description"
                                    fullWidth
                                    multiline
                                    value={feedback.description}
                                    sx={{
                                      "& .MuiInputBase-input": {
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      },
                                      mb: 3,
                                    }}
                                    onChange={(e) =>
                                      handleFeedbackChange(
                                        record.reqId,
                                        feedback.id,
                                        "description",
                                        e.target.value
                                      )
                                    }
                                  />
                                ) : (
                                  <Typography variant="body2">
                                    {feedback.description}
                                  </Typography>
                                )}
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      </>
                    ))}
                  </List>
                </CardContent>
                {/* <CardActions>
                {!isConfirmed(record.reqStatus) && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      mb: 2,
                    }}
                  >
                    <LoadingButton
                      type="submit"
                      color="success"
                      variant="outlined"
                      loading={submitting}
                      onClick={() => handleConfirmation()}
                    >
                      Confirm and notify student(s)
                    </LoadingButton>
                  </Box>
                )}
              </CardActions> */}
              </Card>
            ))}
          </Paper>
          {"edit" === mode && (records?.length > 0) && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                mb: 2,
              }}
            >
              <LoadingButton
                type="submit"
                color="success"
                variant="outlined"
                loading={submitting}
                onClick={() => handleConfirmation()}
              >
                Confirm and notify student(s)
              </LoadingButton>
            </Box>
          )}
        </>
      )}

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Container>
  );
}
