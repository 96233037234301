export const fields = [
  { name: "field", type: "string", defaultValue: "", required: true },
  { name: "name", type: "string", defaultValue: "", required: true },
  { name: "textBook", type: "string", defaultValue: "", required: true },
  { name: "authors", type: "array", defaultValue: ["Author Name"], required: true },
  { name: "edition", type: "number", defaultValue: "", required: false },
  { name: "code", type: "string", defaultValue: "", required: true },
  { name: "section", type: "string", defaultValue: "", required: false },
  { name: "semester", type: "string", defaultValue: undefined, required: true },
  { name: "studentLevel", type: "string", defaultValue: undefined, required: true },
  { name: "startDate", type: "date", defaultValue: new Date(), required: true },
  { name: "endDate", type: "date", defaultValue: new Date(), required: true },
  { name: "caseStudyType", type: "string", defaultValue: undefined, required: true },
  { name: "caseStudyCount", type: "number", defaultValue: "", required: true },
];
