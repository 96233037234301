import React from "react";
import { Toolbar, IconButton, Typography, Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useNavigate } from "react-router-dom";

const PageHeader = ({ title, isChild = false, onRefresh }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 4 }}>
      <Toolbar>
        {isChild ? (
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            <ArrowBackIcon />
          </IconButton>
        ) : null}
        <div style={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {title}
          </Typography>
          {onRefresh ? (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              onClick={onRefresh}
              sx={{ ml: 1 }}
            >
              <RefreshIcon />
            </IconButton>
          ) : null}
        </div>
      </Toolbar>
    </Box>
  );
};

export default PageHeader;
