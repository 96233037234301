import React, { useState } from "react";
import { Container, Grid, Paper, Typography } from "@mui/material";
import { MuiChipsInput } from "mui-chips-input";
import { AnimatedCard } from "./AnimatedCard";
import Notification from "../../../../components/mui/Notification";
import * as casestudyService from "../../services/CasestudyService";
import { isEpmty } from "../../../../helper/TextHelper";
import AssistantIcon from "@mui/icons-material/AutoAwesome";
import { LoadingButton } from "@mui/lab";

const CaseTopics = ({ course, formValues, updateValues }) => {
  const topicsValues = formValues.topics;
  const companyValues = formValues.company;
  const baseInfoValues = formValues.baseInfo;
  const [topicsLoading, setTopicsLoading] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const caseStudyId = formValues.caseStudyId;

  const getTopics = () => {
    setTopicsLoading(true);
    const request = {
      caseStudyId: caseStudyId,
      field: course.field,
      chapterTitle: baseInfoValues.chapters[0],
      chapterTopics: baseInfoValues.chapterTopics?.join(", "),
      companyName: companyValues.names[0],
      studentLevel: course.studentLevel,
      marketTerritory: companyValues.territory,
      marketAnalysisLevel: companyValues.marketAnalysisLevel,
    };
    casestudyService
      .getTopics(request)
      .then((res) => {
        updateValues("topicsCards", res.data);
        updateValues("topicsHiddenCards", res.data);
      })
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message: "Could not fetch topics. Please try again later",
          type: "error",
        });
      })
      .finally(() => {
        setTopicsLoading(false);
      });
  };

  const handleTopicsChips = (newChips) => {
    if (newChips.length > 0) {
      const newChip = newChips[newChips.length - 1];
      updateValues("list", [newChip]);
      if (topicsValues.topicsCards?.some((card) => card.title !== newChip)) {
        updateValues("topicsHiddenCards", topicsValues.topicsCards);
      }
    }
  };

  const handleTopicsCardClick = (card) => {
    updateValues("list", [card.title]);

    const filteredCards = topicsValues.topicsCards.filter(
      (crd) => crd.title !== card.title
    );
    updateValues("topicsHiddenCards", filteredCards);

    if (topicsValues.errors) {
      const targetObject = Object.assign({}, topicsValues.errors);
      targetObject.list = undefined;
      updateValues("errors", targetObject);
    }
  };

  const handleDeleteTopicsChip = (chipValue, chipIndex) => {
    updateValues("list", []);

    // Check if chipValue exists in chaptersCards and not already in hiddenCards
    const existingCard = topicsValues.topicsCards.find(
      (card) => card.title === chipValue
    );
    const isCardHidden = topicsValues.topicsHiddenCards.some(
      (hiddenCard) => hiddenCard.title === chipValue
    );

    if (existingCard && !isCardHidden) {
      topicsValues.topicsHiddenCards.push(existingCard);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <Grid
          container
          spacing={0}
          justify="center"
          alignItems="center"
          sx={{ mb: 1 }}
        >
          <Grid item>
            <Typography sx={{ fontWeight: "bold" }} variant="h6" gutterBottom>
              Main Topics
            </Typography>
          </Grid>

          <Grid item style={{ marginLeft: "auto" }}>
            <LoadingButton
              disabled={topicsValues.topicsCards?.length > 0}
              sx={{ mr: 1 }}
              size="small"
              onClick={() => getTopics()}
              loading={topicsLoading}
              variant="outlined"
              startIcon={<AssistantIcon />}
              loadingPosition="start"
            >
              <span>Assistant</span>
            </LoadingButton>
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }}>
            <MuiChipsInput
              style={{ width: "95%" }}
              disableEdition
              hideClearAll
              value={topicsValues.list}
              onDeleteChip={handleDeleteTopicsChip}
              onChange={handleTopicsChips}
              error={!isEpmty(topicsValues.errors?.list)}
              helperText={topicsValues.errors?.list}
            />
          </Grid>

          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {topicsValues.topicsHiddenCards?.map((card, index) => (
              <AnimatedCard
                key={index}
                card={card}
                index={index}
                handleCardClick={handleTopicsCardClick}
              />
            ))}
          </div>
        </Grid>
      </Paper>

      <Notification
        notify={notify}
        setNotify={setNotify}
        autoHideDuration={4000}
      />
    </Container>
  );
};

export default CaseTopics;
