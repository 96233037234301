import React from "react";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";
import FactoryIcon from "@mui/icons-material/Factory";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { Card, Avatar, Typography, Tooltip } from "@mui/material";
import IconWithText from "../../../../../components/IconWithText";
import { formatDate } from "../../../../../helper/DateHelper";
import { upperCaseFirstLetter } from "../../../../../helper/TextHelper";
import { MuiButton } from "../../../../../components";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import NoteIcon from "@mui/icons-material/Note";
import NoteAddIcon from "@mui/icons-material/NoteAdd";

export default function CaseStudyCard({
  caseStudy,
  handleEdit,
  handleView,
  handleDelete,
  handleTeachingNotes,
  createTeachingNote,
  handlePdf,
  handleAssignments,
}) {
  return (
    <Card elevation={1} sx={{ m: 0.5 }}>
      <CardHeader
        action={
          <IconButton color="secondary" onClick={handleDelete}>
            <DeleteOutlinedIcon />
          </IconButton>
        }
        avatar={
          caseStudy.status === "ACTIVE" ? (
            <Avatar sx={{ bgcolor: "#008000" }}>
              <SpellcheckIcon />
            </Avatar>
          ) : (
            <Avatar sx={{ bgcolor: "#9e9e9e" }}>
              {caseStudy.status?.charAt(0)}
            </Avatar>
          )
        }
        title={
          <Typography
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
            }}
          >
            {caseStudy.title}
          </Typography>
        }
        subheader={"Created on: " + formatDate(caseStudy.createdAt)}
      />

      <CardContent>
        <IconWithText
          icon={<FactoryIcon />}
          text={upperCaseFirstLetter(caseStudy.industry)}
        />
        <IconWithText
          icon={<StorefrontIcon />}
          text={caseStudy.marketType.toUpperCase()}
        />
      </CardContent>

      <CardActions
        disableSpacing
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div>
          <MuiButton
            size="small"
            text="Projects"
            variant="text"
            onClick={handleAssignments}
          />
        </div>
        <div style={{ marginRight: "16px" }}>
          <Tooltip title={"Case Study"}>
            <IconButton color="primary" onClick={handlePdf}>
              <PictureAsPdfIcon />
            </IconButton>
          </Tooltip>
          {caseStudy.teachingNoteId ? (
            <Tooltip title={"Teaching Note"}>
              <IconButton color="primary" onClick={handleTeachingNotes}>
                <NoteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title={"Create Teaching Note"}>
              <IconButton color="success" onClick={createTeachingNote}>
                <NoteAddIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
        {/* <IconButton onClick={handleView}>
        import VisibilityIcon from "@mui/icons-material/Visibility";
          <VisibilityIcon />
        </IconButton> */}
        {/* <IconButton onClick={handleAssignment}>
          <PostAddIcon />
        </IconButton> */}

        {/* ) : null} */}
        {/* {note.userId && note.userId !== null ? ( */}
        {/* <IconButton
            aria-label="edit"
            color="primary"
            onClick={() => handleEdit(content)}
          >
            <EditOutlinedIcon />
          </IconButton> */}
        {/* ) : null} */}
        {/* <IconButton color="success" onClick={() => handleCasestudy(content.id)}>
          <AssignmentIcon />
        </IconButton> */}

        {/* <IconButton aria-label="edit" color="primary" onClick={handleEdit}>
          <EditOutlinedIcon />
        </IconButton> */}
      </CardActions>
    </Card>
  );
}
