import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  Typography,
  FormControlLabel,
  Avatar,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Checkbox, Container, Paper } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Form, useForm } from "../../../components/mui/useForm";
import useSessionStorage from "../../../components/useSessionStorage";
import useLocalStorage from "../../../components/useLocalStorage";
import { MuiButton, MuiInput } from "../../../components";
import LoadingButton from "@mui/lab/LoadingButton";
import * as authService from "../services/AuthService";
import * as userService from "../services/UserService";
import Notification from "../../../components/mui/Notification";
import { useNavigate, useLocation } from "react-router-dom";
import { loginSuccess } from "../reducers/actions";
import { AppContext } from "../App";
import { FORGOT_PASS_ROUTE, SINGUP_ROUTE } from "../helper/Constants";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 20,
    margin: "20px auto",
  },
  btn: {
    margin: "8px 0",
  },
}));

const initialFValues = {
  username: "",
  password: "",
};

export default function Login(props) {
  const { appState, dispatch } = useContext(AppContext);
  const classes = useStyles();
  const navigate = useNavigate();
  const setAuthToken = useSessionStorage("authToken")[1];
  const { state } = useLocation();
  const [rememberMe, setRememberMe] = useLocalStorage("rememberMe", true);
  const [localUsername, setLocalUsername] = useLocalStorage("userName");
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("username" in fieldValues)
      temp.username =
        fieldValues.username !== "" && /$^|.+@.+..+/.test(fieldValues.username)
          ? ""
          : "Email is not valid.";
    if ("password" in fieldValues)
      temp.password = fieldValues.password ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const handleNavigation = () => {
    if (state?.redirect) {
      navigate(state.redirect);
    } else if (appState.lastVisitedPage && appState.lastVisitedPage !== "") {
      navigate(appState.lastVisitedPage);
    } else {
      navigate("/");
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  useEffect(() => {
    if (localUsername) {
      setValues({ ...values, username: localUsername });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      authService
        .login(values)
        .then((response) => {
          handleRememberMeInLocalStorage();
          setAuthToken(response.data.token);
          userService
            .profile()
            .then((res) => {
              dispatch(loginSuccess(res.data));
              handleNavigation();
            })
            .catch((e) => {
              dispatch(loginSuccess({ firstName: "Guest" }));
              handleError(e);
              handleNavigation();
            });
          // dispatch(loginSuccess(response.data));
          // handleNavigation();
        })
        .catch((error) => {
          handleError(error);
        })
        .finally(() => setLoading(false));
    }
  };

  function handleError(error) {
    console.log(error);
    if (error.response) {
      console.log("error.response: ", error.response);

      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (error.response.status === 428) {
        setNotify({
          isOpen: true,
          message: "You should verify your email address",
          type: "error",
        });
      } else if (error.response.status === 417) {
        setNotify({
          isOpen: true,
          message: "You do not have access",
          type: "error",
        });
      } else {
        setNotify({
          isOpen: true,
          message: "Username or password is not correct",
          type: "error",
        });
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log("error.request: ", error.request);
      setNotify({
        isOpen: true,
        message: "Could not connect to server. Please try again later",
        type: "error",
      });
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("error.config: ", error.config);
    }
  }

  function handleRememberMeInLocalStorage() {
    if (rememberMe) {
      setLocalUsername(values.username);
    } else setLocalUsername(undefined);
  }

  const handleRememberMe = (event) => {
    setRememberMe(event.target.checked);
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
      <Form onSubmit={handleSubmit}>
        <Paper elevation={10} className={classes.paper}>
          <Grid container justifyContent="center">
            <Grid item align="center">
              <Avatar sx={{ bgcolor: "#1bbd7e" }}>
                <LockOutlinedIcon />
              </Avatar>
              <h2>Sign In</h2>
              <MuiInput
                name="username"
                label="Email address"
                value={values.username}
                onChange={handleInputChange}
                error={errors.username}
              />
              <MuiInput
                name="password"
                label="Password"
                type="password"
                value={values.password}
                onChange={handleInputChange}
                error={errors.password}
              />
            </Grid>
            <Grid item lg={8} xs={9} sm={8} md={8}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="rememberMe"
                    onChange={handleRememberMe}
                    color="primary"
                    checked={rememberMe}
                  />
                }
                label="Remember me"
              />
            </Grid>
            <Grid item lg={8} xs={9} sm={8} md={8}>
              <LoadingButton
                type="submit"
                sx={{ mt: 1, mr: 1 }}
                color="success"
                variant="contained"
                loading={loading}
              >
                Submit
              </LoadingButton>
              <MuiButton
                sx={{ mt: 1, mr: 1 }}
                size="medium"
                text="Cancel"
                color="info"
                variant="contained"
                onClick={() => navigate("/")}
              />
            </Grid>
            <Grid
              item
              lg={8}
              xs={9}
              sm={8}
              md={8}
              sx={{ mt: "1em", mb: "2em" }}
            >
              <Button
                variant="text"
                onClick={() => navigate(FORGOT_PASS_ROUTE)}
              >
                Forgot password?
              </Button>
              <Typography>
                {" "}
                Don't have an account?&nbsp;
                <Button variant="text" onClick={() => navigate(SINGUP_ROUTE)}>
                  Sign Up
                </Button>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
    </Container>
  );
}
