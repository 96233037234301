import React, { useState } from "react";
import { Grid, Typography, List, ListItem, ListItemText } from "@mui/material";
import Notification from "../../../../components/mui/Notification";
import { MuiButton } from "../../../../components";

const PurchaseCard = ({ caseStudy, handleCheckout }) => {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const insertSpacesBetweenWords = (string) => {
    return string.replace(/([a-z])([A-Z])/g, "$1 $2");
  };

  const renderListItems = (data) => {
    return Object.entries(data).map(([key, value]) => {
      if (excludedItems.includes(key)) {
        return null; // Exclude this item from rendering
      }
      const capitalizedKey = capitalizeFirstLetter(key);
      const formattedKey = insertSpacesBetweenWords(capitalizedKey);
      return (
        <ListItem key={key}>
          <ListItemText primary={formattedKey} sx={{ mr: 2 }} />
          <Typography variant="body1" fontWeight="medium">
            {value}
          </Typography>
        </ListItem>
      );
    });
  };

  const excludedItems = ["id", "currencyCode", "currencyName"];

  return (
    <>
      <List sx={{ width: "100%", bgcolor: "background.paper" }} disablePadding>
        {renderListItems(caseStudy)}
      </List>
      <Grid item xs={12} sx={{ mt: 2 }} align="center">
        <MuiButton
          text={"Pay " + caseStudy.price + " " + caseStudy.currencyName}
          color="info"
          variant="outlined"
          onClick={handleCheckout}
        />
      </Grid>

      <Notification
        notify={notify}
        setNotify={setNotify}
        autoHideDuration={4000}
      />
    </>
  );
};

export default PurchaseCard;
