import React, { useContext, useState } from "react";
import { InstructorInstitutionList } from "../institution";
import useRequireLogin from "../../../../common/useRequireLogin";
import { INSTRUCTOR_BOARD_ROUTE, LOGIN_ROUTE } from "../../helper/Constants";
import { AppContext } from "../../App";

export const Board = () => {
  const { appState } = useContext(AppContext);
  const [readyToLoad, setReadyToLoad] = useState(false);
  useRequireLogin(appState, LOGIN_ROUTE, INSTRUCTOR_BOARD_ROUTE, () =>
    setReadyToLoad(true)
  );

  if (readyToLoad) {
    return <InstructorInstitutionList />;
  }
};
