import React, { useState } from "react";
import { Container, Grid, Paper, Typography } from "@mui/material";
import { MuiChipsInput } from "mui-chips-input";
import * as casestudyService from "../../services/CasestudyService";
import { AnimatedCard } from "./AnimatedCard";
import Notification from "../../../../components/mui/Notification";
import { isEpmty } from "../../../../helper/TextHelper";
import AssistantIcon from "@mui/icons-material/AutoAwesome";
import { LoadingButton } from "@mui/lab";

const CaseSubTopics = ({ course, formValues, updateValues }) => {
  const subTopicsValues = formValues.subTopics;
  const topicsValues = formValues.topics;
  const companyValues = formValues.company;
  const [subTopicsLoading, setSubTopicsLoading] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const caseStudyId = formValues.caseStudyId;

  const getSubTopics = () => {
    setSubTopicsLoading(true);
    const request = {
      caseStudyId: caseStudyId,
      field: course.field,
      topics: topicsValues.list?.join(", "),
      companyName: companyValues.names[0],
      studentLevel: course.studentLevel,
      marketTerritory: companyValues.territory,
    };
    casestudyService
      .getSubTopics(request)
      .then((res) => {
        updateValues("subTopicsCards", res.data);
        updateValues("subTopicsHiddenCards", res.data);
      })
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message: "Could not fetch sub-topics. Please try again later",
          type: "error",
        });
      })
      .finally(() => {
        setSubTopicsLoading(false);
      });
  };

  const handleChips = (newChips) => {
    updateValues("list", newChips);
  };

  const handleCardClick = (card) => {
    updateValues("list", [...subTopicsValues.list, card.title]);

    const filteredCards = subTopicsValues.subTopicsHiddenCards.filter(
      (crd) => crd.title !== card.title
    );
    updateValues("subTopicsHiddenCards", filteredCards);

    if (subTopicsValues.errors) {
      updateValues(
        "errors",
        Object.assign(
          {},
          subTopicsValues.errors,
          delete subTopicsValues.errors.list
        )
      );
    }
  };

  const handleDeleteChip = (chipValue, chipIndex) => {
    const filteredChips = subTopicsValues.list.filter(
      (chip) => chip !== chipValue
    );
    updateValues("list", filteredChips);

    if (
      subTopicsValues.subTopicsCards?.some((card) => card.title === chipValue)
    ) {
      // Check if the card is not already in hiddenCards
      if (
        !subTopicsValues.subTopicsHiddenCards.some(
          (hiddenCard) => hiddenCard.title === chipValue
        )
      ) {
        subTopicsValues.subTopicsHiddenCards.push(
          subTopicsValues.subTopicsCards.find(
            (card) => card.title === chipValue
          )
        );
      }
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <Grid
          container
          spacing={0}
          justify="center"
          alignItems="center"
          sx={{ mb: 1 }}
        >
          <Grid item>
            <Typography sx={{ fontWeight: "bold" }} variant="h6" gutterBottom>
              Subtopics
            </Typography>
          </Grid>
          <Grid item style={{ marginLeft: "auto" }}>
            <LoadingButton
              disabled={subTopicsValues.subTopicsCards?.length > 0}
              sx={{ mr: 1 }}
              size="small"
              onClick={() => getSubTopics()}
              loading={subTopicsLoading}
              variant="outlined"
              startIcon={<AssistantIcon />}
              loadingPosition="start"
            >
              <span>Assistant</span>
            </LoadingButton>
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }}>
            <MuiChipsInput
              style={{ width: "95%" }}
              disableEdition
              hideClearAll
              value={subTopicsValues.list}
              onDeleteChip={handleDeleteChip}
              onChange={handleChips}
              error={!isEpmty(subTopicsValues.errors?.list)}
              helperText={subTopicsValues.errors?.list}
            />
          </Grid>

          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {subTopicsValues.subTopicsHiddenCards?.map((card, index) => (
              <AnimatedCard
                key={index}
                card={card}
                index={index}
                handleCardClick={handleCardClick}
              />
            ))}
          </div>
        </Grid>
      </Paper>

      <Notification
        notify={notify}
        setNotify={setNotify}
        autoHideDuration={4000}
      />
    </Container>
  );
};

export default CaseSubTopics;
