import React from "react";
import { Card, CardContent, Typography, Zoom } from "@mui/material";

export const TextBookChapterTopicCard = ({ card, index, handleCardClick }) => {
  const timeout = 500 + index * 200; // Adjust the timing as needed

  return (
    <Zoom in={true} timeout={timeout}>
      <Card
        key={index}
        onClick={() => handleCardClick(card)}
        style={{
          margin: "10px",
          cursor: "pointer",
          transition: "transform 0.3s",
          "&:hover": {
            transform: "scale(1.1)",
          },
        }}
      >
        <CardContent>
          <Typography sx={{ mb: 0.5 }}>{card}</Typography>
        </CardContent>
      </Card>
    </Zoom>
  );
};
