import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

const AutoCompleteAsync = ({ name, label, getOptions, onChange, error = null, ...other }) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    getOptions()
      .then((response) => {
        if (active) {
          setOptions(response.data);
        }
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
      });

    return () => {
      active = false;
    };
    // eslint-disable-next-line
  }, [loading]);

  return (
    <Autocomplete
      id="auto-complete-asynchronous"
      name={name}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={onChange}
      isOptionEqualToValue={(option, value) => option.title === value.title}
      getOptionLabel={(option) => option.title}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          {...other}
          {...(error && {error:true, helperText:error})}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default AutoCompleteAsync;
