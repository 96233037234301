import React, { useState } from 'react'
import { Box, Button, FormControl, FormHelperText } from '@mui/material';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles((props) => ({
    root: {
        display: 'flex',
        alignItems: props.alignItems,
        justifyContent: props.justifyContent,
    },
}))


export default function ImageUpload(props) {

    const classes = useStyles(props);
    const [disabled] = useState(false)
    const { label, error = null, onFileSelected, acceptType, alignItems = 'center', justifyContent = 'center', border = 1 } = props;

    return (
            <FormControl {...(error && {error:true})}
                sx={{ display: 'flex', flexDirection: 'column', justifyContent: {justifyContent}, alignItems: {alignItems}, }}>
                    <Box component="span" className={classes.root}
                    sx={{
                        border: {border},
                        borderRadius: 1,
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                        paddingY: 3,
                        paddingX: 1,
                        '&:hover': {
                            borderColor: disabled ? undefined : 'text.primary',
                        },
                        '&:focus-within': {
                            borderColor: 'primary.main',
                            borderWidth: 2,
                        },
                    }}>
                        <label htmlFor="btn-upload">
                            <input
                                id="btn-upload"
                                name="btn-upload"
                                style={{ display: 'none' }}
                                type="file"
                                accept={acceptType}
                                onChange={onFileSelected} />
                            <Button
                                className="btn-choose"
                                variant="outlined"
                                component="span" >
                                {label}
                            </Button>
                        </label>
                    </Box>
                    {error && <FormHelperText>{error}</FormHelperText>}
            </FormControl>
    )
}