import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';

const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(5),
    border: "1px solid grey", // Moved border to class level
    textAlign: "center",
    borderRadius: theme.shape.borderRadius, // Adding border radius for better appearance
    backgroundColor: theme.palette.background.paper, // Ensure background color for consistent theme appearance
  },
  header: {
    marginBottom: theme.spacing(2), // Adding margin bottom to header Typography
  },
  message: {
    color: theme.palette.text.secondary, // Adjusting color for message Typography
  }
}))

export const NoContent = (props) => { 
  const classes = useStyles()
  const { header, message } = props

  return (
      <Box className={classes.content}>
        <Typography variant="h5" gutterBottom component="div" className={classes.header}>
          {header} 
        </Typography>
        <Typography variant="body1" gutterBottom component="div" className={classes.message}>
          {message}
        </Typography>
      </Box>
  );
};
