import React, { useState, useEffect } from "react";
import { Container, Grid, Skeleton } from "@mui/material";
import { useLocation } from "react-router-dom";
import * as groupService from "../../../services/AssignmentGroupService";
import ConfirmDialog from "../../../../../components/ConfirmDialog";
import Notification from "../../../../../components/mui/Notification";
import PageHeader from "../../../../../components/PageHeader";
import GroupCard from "./GroupCard";
import { NoContent } from "../../../../../components/NoContent";
import { upperCaseFirstLetter } from "../../../../../helper/TextHelper";

export default function List() {
  const { state } = useLocation();

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState([]);
  const assignment = state.assignment;

  useEffect(() => {
    groupService
      .getAll(assignment.id)
      .then((response) => {
        setGroups(response.data);
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not connect to server. Please try again later.",
          type: "error",
        });
      })
      .finally(() => setLoading(false));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <PageHeader title={upperCaseFirstLetter(assignment.title) + " Groups"} isChild={true} />
      <Grid container spacing={2}>
        {loading
          ? Array.from(new Array(4)).map((item, index) => (
              <Grid
                key={index}
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                sx={{ mt: 2 }}
              >
                <Skeleton variant="rectangular" height={118} />
                <Skeleton />
                <Skeleton width="60%" />
              </Grid>
            ))
          : (groups.length > 0 &&
              groups.map((group, index) => (
                <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                  <GroupCard group={group} />
                </Grid>
              ))) || (
              <Grid item xs={12}>
                <NoContent
                  header="No group has been created yet!"
                  message="Let's try again later"
                />
              </Grid>
            )}
      </Grid>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Container>
  );
}
