import React, { useState, useEffect, useContext } from "react";
import { Container, Grid, Skeleton } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import * as caseStudyService from "../../services/CasestudyService";
import CaseStudyCard from "./components/CasestudyCard";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import Notification from "../../../../components/mui/Notification";
import NewCard from "../../../../components/NewCard";
import PageHeader from "../../../../components/PageHeader";
import { formatDate } from "../../../../helper/DateHelper";
import { CASE_STUDY_ASSIGNMENTS, CASE_STUDY_NEW } from "../../helper/Constants";
import { AppContext } from "../../App";

export default function List() {
  const { appState } = useContext(AppContext);
  const navigate = useNavigate();
  const { state } = useLocation();

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [loading, setLoading] = useState(true);
  const [caseStudies, setCaseStudies] = useState([]);
  const course = state.course;

  useEffect(() => {
    // if (!isEpmty(state)) {
    // const eventId = state.eventId;
    // setEventId(eventId);
    caseStudyService
      .getAll(course.id)
      .then((response) => {
        setCaseStudies(response.data);
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not connect to server. Please try again later.",
          type: "error",
        });
      })
      .finally(() => setLoading(false));
    // } else setLoading(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAssignments = (casestudy) => {
    navigate(CASE_STUDY_ASSIGNMENTS, { state: { casestudy } });
  };

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    caseStudyService
      .del(id)
      .then(() => {
        const newCases = caseStudies.filter((item) => item.id !== id);
        setCaseStudies(newCases);
        setNotify({
          isOpen: true,
          message: "Deleted Successfully",
          type: "error",
        });
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not delete a case study. Please try again later.",
          type: "error",
        });
      });
  };

  const handleView = (caseStudy) => {
    navigate("/casestudy/view", {
      state: { course, caseStudy },
    });
  };

  const handleTeachingNoteCreation = (caseStudy, course) => {
    const request = { courseId: course.id, caseStudyId: caseStudy.id };
    caseStudyService
      .createTeachingNote(request)
      .then((response) => {
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        if (e.response && e.response.status === 409) {
          setNotify({
            isOpen: true,
            message:
              "Teaching notes creation still in progress. Please try again later.",
            type: "warning",
          });
        } else {
          setNotify({
            isOpen: true,
            message:
              "Could not create a case study teaching note. Please try again later.",
            type: "error",
          });
        }
      });
  };

  const handleTeachingNotes = (caseStudy, course) => {
    caseStudyService
      .getTeachingNotes(caseStudy.id)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Teaching_Notes_" +
            course.code +
            "_" +
            course.section +
            "_" +
            formatDate(new Date()) +
            ".pdf"
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        if (e.response && e.response.status === 404) {
          setNotify({
            isOpen: true,
            message:
              "Teaching notes creation still in progress. Please try again later.",
            type: "warning",
          });
        } else {
          setNotify({
            isOpen: true,
            message: "Could not connect to server. Please try again later.",
            type: "error",
          });
        }
      });
  };

  const handlePdf = (caseStudyId, course) =>
    caseStudyService
      .getPdf(caseStudyId)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Case_study_" +
            course.code +
            "_" +
            course.section +
            "_" +
            formatDate(new Date()) +
            ".pdf"
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        if (e.response && e.response.status === 404) {
          setNotify({
            isOpen: true,
            message: "Could not find a pdf. Please try again later.",
            type: "warning",
          });
        } else {
          setNotify({
            isOpen: true,
            message: "Could not connect to server. Please try again later.",
            type: "error",
          });
        }
      });

  const handleEdit = (caseStudy) => {
    navigate("/casestudy/edit", { state: { course, caseStudy } });
  };

  const handleNew = () => {
    if (appState.isLoggedIn && !appState.userInfo?.hasPromptPermission) {
      setNotify({
        isOpen: true,
        message:
          "No more credits avaiable. Please contact us to get unblocked!",
        type: "error",
      });
    } else {
      navigate(CASE_STUDY_NEW, { state: { course } });
    }
  };

  const handleDelete = (id) => {
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this record?",
      subTitle: "You can't undo this operation",
      onConfirm: () => {
        onDelete(id);
      },
    });
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <PageHeader title="List of Case Studies" isChild={true} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <NewCard title={"New Case Study"} onclick={handleNew} />
        </Grid>
        {loading
          ? Array.from(new Array(3)).map((item, index) => (
              <Grid
                key={index}
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                sx={{ mt: 2 }}
              >
                <Skeleton variant="rectangular" height={118} />
                <Skeleton />
                <Skeleton width="60%" />
              </Grid>
            ))
          : caseStudies.map((caseStudy, index) => (
              <Grid key={index} item xs={12} sm={6} md={4}>
                <CaseStudyCard
                  caseStudy={caseStudy}
                  handleEdit={() => handleEdit(caseStudy)}
                  handleView={() => handleView(caseStudy)}
                  handleDelete={() => handleDelete(caseStudy.id)}
                  handleTeachingNotes={() =>
                    handleTeachingNotes(caseStudy, course)
                  }
                  createTeachingNote={() =>
                    handleTeachingNoteCreation(caseStudy, course)
                  }
                  handlePdf={() => handlePdf(caseStudy.id, course)}
                  handleAssignments={() => handleAssignments(caseStudy)}
                />
              </Grid>
            ))}
      </Grid>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Container>
  );
}
