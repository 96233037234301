import React, { useState } from "react";
import {
  Autocomplete,
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { MuiInput, MuiButton, MuiDatePicker } from "../../../../components";
import { useForm, Form } from "../../../../components/mui/useForm";
import { useNavigate, useLocation } from "react-router-dom";
import Notification from "../../../../components/mui/Notification";
import LoadingButton from "@mui/lab/LoadingButton";
import PageHeader from "../../../../components/PageHeader";
import * as courseService from "../../services/CourseService";
import { courseFields, validateCourse, formatDates } from "./core";
import { isEpmty } from "../../../../helper/TextHelper";
import { MuiChipsInput } from "mui-chips-input";

const studentLevelOptions = ["Freshman", "Junior", "Sophomore", "Senior"];
const semesterOptions = ["Fall", "Summer", "Winter"];
const caseStudyTypeOptions = ["Individual", "Group", "Both"];

export default function Upsert() {
  const { state } = useLocation();
  const [isInEditMode] = useState(state?.editMode || false);
  const navigate = useNavigate();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [loading, setLoading] = useState(false);

  const validate = (fieldValues = values) => {
    validateCourse(values);
    let temp = { ...values.errors };
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  function extractDefaultValues() {
    if (!isInEditMode) {
      const initialValues = {};
      courseFields.forEach((field) => {
        initialValues[field.name] = field.defaultValue;
      });
      return initialValues;
    } else {
      return { ...state.content };
    }
  }

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(extractDefaultValues(), false, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate(values)) {
      upsert(values);
    }
  };

  const upsert = (obj) => {
    setLoading(true);
    formatDates(obj);
    obj["institutionId"] = state.institutionId;
    if (isEpmty(obj.id)) {
      courseService
        .create(obj)
        .then((response) => {
          setNotify({
            isOpen: true,
            message: "Submitted Successfully",
            type: "success",
          });
        })
        .catch((e) => {
          console.error(`Error: ${e.message}`);
          setNotify({
            isOpen: true,
            message: "Could not create a course. Please try again later.",
            type: "error",
          });
        })
        .finally(() => setLoading(false));
    } else {
      courseService
        .update(obj)
        .then((response) => {
          setNotify({
            isOpen: true,
            message: "Submitted Successfully",
            type: "success",
          });
        })
        .catch((e) => {
          console.error(`Error: ${e.message}`);
          setNotify({
            isOpen: true,
            message: "Could not update a course. Please try again later.",
            type: "error",
          });
        })
        .finally(() => setLoading(false));
    }
  };

  const handleAuthorsChange = (newChips) => {
    const authorsField = courseFields.find(
      (field) => field.name === "authors"
    ).defaultValue;
    const chips = newChips.filter((f) => f !== authorsField[0]);
    setValues({
      ...values,
      authors: chips,
    });
  };

  const handleAuthorsDelete = (chipValue, chipIndex) => {
    const updatedAuthors = values.authors.filter(
      (author) => author !== chipValue
    );
    setValues({
      ...values,
      authors: updatedAuthors,
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <PageHeader
          title={isInEditMode ? values.name : "Add a New Course"}
          isChild={true}
        />
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <React.Fragment>
            <Typography sx={{ fontWeight: "bold" }} variant="h5" gutterBottom>
              Course
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <MuiInput
                  // required
                  error={errors?.name}
                  id="name"
                  name="name"
                  label="Name"
                  value={values.name}
                  fullWidth
                  variant="standard"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MuiInput
                  // required
                  error={errors?.field}
                  id="field"
                  name="field"
                  label="Major"
                  value={values.field}
                  fullWidth
                  variant="standard"
                  onChange={handleInputChange}
                  helperText="Eg. Marketing"
                />
              </Grid>

              {isInEditMode ? null : (
                <Grid item xs={12}>
                  <Paper
                    variant="outlined"
                    sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
                  >
                    <React.Fragment>
                      <Typography variant="h6" gutterBottom>
                        Textbook Details
                      </Typography>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <MuiInput
                            // required
                            error={errors.textBook}
                            id="textBook"
                            name="textBook"
                            label="Title"
                            value={values.textBook}
                            fullWidth
                            variant="standard"
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MuiChipsInput
                            label="Authors"
                            style={{ width: "95%" }}
                            // hideClearAll
                            // disableEdition
                            value={values.authors}
                            onDeleteChip={handleAuthorsDelete}
                            onChange={handleAuthorsChange}
                            error={!isEpmty(errors.authors)}
                            placeholder="Type Author name and press enter"
                            helperText={
                              !isEpmty(errors.authors) &&
                              "Press Enter after adding author name"
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MuiInput
                            id="edition"
                            name="edition"
                            label="Edition"
                            type="number"
                            value={values.edition}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={handleInputChange}
                            error={errors.edition}
                          />
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  </Paper>
                </Grid>
              )}

              <Grid item xs={12} sm={6}>
                <MuiInput
                  // required
                  error={errors.code}
                  id="code"
                  name="code"
                  label="Course Code"
                  value={values.code}
                  fullWidth
                  variant="standard"
                  onChange={handleInputChange}
                  helperText="Eg. Mark101"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MuiInput
                  // required
                  error={errors.section}
                  id="section"
                  name="section"
                  label="Section"
                  value={values.section}
                  fullWidth
                  variant="standard"
                  onChange={handleInputChange}
                  helperText="Eg. AA"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Autocomplete
                  disablePortal
                  id="combo-box-semester"
                  value={values.semester}
                  options={semesterOptions}
                  onChange={(event, newValue) => {
                    setValues({ ...values, semester: newValue });
                  }}
                  renderInput={(params) => (
                    <MuiInput
                      {...params}
                      // required
                      error={errors.semester}
                      id="semester"
                      name="semester"
                      label="Semester"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              {/* <Grid item xs={12} sm={6}>
                <Autocomplete
                  disablePortal
                  id="combo-box-institution"
                  value={values.institution}
                  options={[{ label: "University" }, { label: "College" }]}
                  onChange={(event, newValue) => {
                    setValues({ ...values, institution: newValue.label });
                  }}
                  renderInput={(params) => (
                    <MuiInput
                      {...params}
                      required
                      error={errors.Institution}
                      id="institution"
                      name="institution"
                      label="Institution"
                      fullWidth
                    />
                  )}
                />
              </Grid> */}

              <Grid item xs={12} sm={6}>
                <Autocomplete
                  disablePortal
                  id="combo-box-st-level"
                  value={values.studentLevel}
                  options={studentLevelOptions}
                  onChange={(event, newValue) => {
                    setValues({ ...values, studentLevel: newValue });
                  }}
                  renderInput={(params) => (
                    <MuiInput
                      {...params}
                      // required
                      error={errors.studentLevel}
                      id="studentLevel"
                      name="studentLevel"
                      label="Student Level"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <MuiDatePicker
                  // required
                  minDate={new Date()}
                  openTo="year"
                  views={["year", "month", "day"]}
                  id="startDate"
                  name="startDate"
                  label="Start Date"
                  value={values.startDate}
                  onChange={handleInputChange}
                  error={errors.startDate}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MuiDatePicker
                  // required
                  minDate={new Date()}
                  openTo="year"
                  views={["year", "month", "day"]}
                  id="endDate"
                  name="endDate"
                  label="End Date"
                  value={values.endDate}
                  onChange={handleInputChange}
                  error={errors.endDate}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Autocomplete
                  disablePortal
                  id="combo-box-case-study-type"
                  value={values.caseStudyType}
                  options={caseStudyTypeOptions}
                  onChange={(event, newValue) => {
                    setValues({ ...values, caseStudyType: newValue });
                  }}
                  renderInput={(params) => (
                    <MuiInput
                      {...params}
                      // required
                      error={errors.caseStudyType}
                      id="caseStudyType"
                      name="caseStudyType"
                      label="Type of Case study"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MuiInput
                  id="caseStudyCount"
                  name="caseStudyCount"
                  label="Number of Case Study"
                  type="number"
                  value={values.caseStudyCount}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleInputChange}
                  error={errors.caseStudyCount}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        </Paper>

        <Grid item xs={4} sm={8} md={8} sx={{ mt: 2 }} align="center">
          <Stack
            spacing={{ xs: 1, sm: 2 }}
            direction={{ xs: "column", sm: "row" }}
            sx={{ ml: 1 }}
          >
            <LoadingButton
              type="submit"
              color="success"
              variant="outlined"
              loading={loading}
            >
              Submit
            </LoadingButton>

            <MuiButton
              text="Reset"
              color="inherit"
              variant="outlined"
              onClick={resetForm}
            />

            <MuiButton
              text="Cancel"
              color="info"
              variant="outlined"
              onClick={() => {
                navigate(-1);
              }}
            />
          </Stack>
        </Grid>
        <Notification notify={notify} setNotify={setNotify} />
      </Container>
    </Form>
  );
}
