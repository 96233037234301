import React from "react";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { Card, Typography } from "@mui/material";
import { upperCaseFirstLetter } from "../../../../../helper/TextHelper";

export default function GroupCard({ group }) {
  return (
    <Card elevation={1} sx={{ m: 0.5 }}>
      <CardHeader
        title={
          <Typography
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
            }}
          >
            {upperCaseFirstLetter(group.title)}
          </Typography>
        }
        subheader={"Created date: " + group.createdAt}
      />

      <CardContent></CardContent>

      <CardActions
        disableSpacing
        sx={{ display: "flex", justifyContent: "space-between" }}
      ></CardActions>
    </Card>
  );
}
