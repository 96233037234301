import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
} from "@mui/material";

const ViewCard = ({ headerTitle, content }) => {

  return (
    <Card>
      <CardHeader title={headerTitle} />
      <CardContent>
        <Typography>{content}</Typography>
      </CardContent>
    </Card>
  );
};

export default ViewCard;
