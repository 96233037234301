import React, { useState } from "react";
import {
  Collapse,
  Grid,
  Stack,
  List,
  ListItemText,
  ListItemButton,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import EditableTypewriterInput from "../../../../components/EditableTypewriterInput";

const EditableReviewCard = ({ content, onChange, isActive = true, toggle }) => {
  const [expanded, setExpanded] = useState();
  const [removed, setRemoved] = useState(!isActive);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <List
      sx={{
        width: "100%",
        bgcolor: "background.paper",
      }}
    >
      <ListItemButton
        onClick={!removed && isActive ? handleExpandClick : undefined}
        sx={{
          border: removed || !isActive ? "2px solid" : undefined,
          borderColor: removed || !isActive ? red[500] : undefined,
        }}
      >
        <ListItemText
          primary={
            content.substring(0, 50) + (content.length > 50 ? "..." : "")
          }
        />
        {expanded ? (
          <ExpandLessIcon />
        ) : removed ? (
          <IconButton
            color="success"
            onClick={() => {
              toggle(true);
              setRemoved(false);
              setExpanded(true);
            }}
          >
            <Typography variant="subtitle2">Restore</Typography>
          </IconButton>
        ) : (
          <ExpandMoreIcon />
        )}
      </ListItemButton>
      <Collapse
        in={expanded && isActive}
        timeout="auto"
        unmountOnExit
        sx={{ ml: 1, mt: 1 }}
      >
        <Grid container spacing={0}>
          <Grid item xs={11}>
            <EditableTypewriterInput
              multiline
              value={content}
              fullWidth
              onChange={onChange}
            />
          </Grid>
          <Grid item>
            <Stack direction="column" spacing={0} alignItems="flex-end">
              {removed ? (
                <IconButton
                  color="success"
                  onClick={() => {
                    toggle(true);
                    setRemoved(false);
                    setExpanded(true);
                  }}
                >
                  <RestoreFromTrashIcon />
                </IconButton>
              ) : (
                <IconButton
                  color="error"
                  onClick={() => {
                    toggle(false);
                    setRemoved(true);
                    setExpanded(false);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Collapse>
    </List>
  );
};

export default EditableReviewCard;
