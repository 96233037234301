import axios from "axios";
import { LOGIN_ROUTE } from "../helper/Constants";

export const httpClient = (
  isBlob = false,
  token = window.sessionStorage.getItem("authToken")
) => {
  const instance = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
    headers: {
      Authorization: "Bearer " + JSON.parse(token),
    },
  });

  // Add a request interceptor
  instance.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      if (isBlob) config.responseType = "blob";
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      console.log("error.response: ", error.response);
      if (error.response.status === 401 || error.response.status === 403) {
        window.sessionStorage.removeItem("authToken");
        window.location.href = LOGIN_ROUTE;
      }
      return Promise.reject(error);
    }
  );

  return instance;
};
