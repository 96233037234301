import React, { useState } from "react";
import { TextField } from "@mui/material";
import { isEpmty } from "../helper/TextHelper";

const EditableTypewriterInput = (props) => {
  const { name, label, value, error = null, onChange, ...other } = props;
  // eslint-disable-next-line
  const [focused, setFocused] = useState(isEpmty(value));

  // useEffect(() => {
  //   if (currentIndex < value.length) {
  //     const timeout = setTimeout(() => {
  //       setCurrentText((prevText) => prevText + value[currentIndex]);
  //       setCurrentIndex((prevIndex) => prevIndex + 1);
  //     }, delay);

  //     return () => clearTimeout(timeout);
  //   }
  // }, [currentIndex, delay, value]);

  return (
    <TextField
      autoFocus
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={(event) => setFocused(false)}
      {...other}
      {...(error && { error: true, helperText: error })}
    />
    // <>
    //   {!focused ? (
    //     <Typography
    //       onClick={() => {
    //         setFocused(true);
    //       }}
    //     >
    //       {value}
    //     </Typography>
    //   ) : (
    //     <TextField
    //       autoFocus
    //       label={label}
    //       name={name}
    //       value={value}
    //       onChange={onChange}
    //       onBlur={(event) => setFocused(false)}
    //       {...other}
    //       {...(error && { error: true, helperText: error })}
    //     />
    //   )}
    // </>
  );
};

export default EditableTypewriterInput;
