import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";

const SaveLoadingButton = ({
  title,
  handleClick,
  isLoading,
  isSaved,
  isDisabled,
}) => {
  return (
    <LoadingButton
      disabled={isDisabled}
      size="small"
      onClick={() => handleClick()}
      loading={isLoading}
      loadingPosition="start"
      variant="outlined"
      startIcon={isSaved ? <DoneRoundedIcon /> : <SaveIcon />}
    >
      <span>{title || "Save Changes"}</span>
    </LoadingButton>
  );
};

export default SaveLoadingButton;
