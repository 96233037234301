export const fields = [
  {
    name: "chapters",
    type: "array",
    defaultValue: [],
    required: true,
  },  {
    name: "chapterTopics",
    type: "array",
    defaultValue: [],
    required: true,
  },
  {
    name: "marketType",
    type: "string",
    defaultValue: "b2b",
    required: true,
  },
];

export const label = "General Information";
