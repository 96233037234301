import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import { Slider } from "@mui/material";

// Copied from https://github.com/mui/material-ui/blob/v5.16.7/docs/data/material/getting-started/templates/landing-page/components/Features.js

const items = [
  {
    icon: <MoneyOffIcon />,
    title: "Promotion",
    description:
      "You are eligible to give discount to the students on this project!. Please use the slider below to set a discount percentage.",
  },
];

export default function Promotion({ discount, setDiscount }) {
  const handleSliderChange = (event, newValue) => {
    // setValue(newValue);
    setDiscount(newValue);
  };

  function valuetext(value) {
    return `${value}°C`;
  }

  return (
    <Container id="promotion" sx={{ py: { xs: 4 } }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            // useFlexGap
            sx={{ width: "100%", display: { sm: "flex" } }}
            // sx={{ width: "100%", display: { xs: "none", sm: "flex" } }}
          >
            {items.map(({ icon, title, description }, index) => (
              <Card
                key={index}
                variant="outlined"
                component={Button}
                sx={{
                  p: 3,
                  height: "fit-content",
                  //   width: "100%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    textAlign: "left",
                    flexDirection: { xs: "column", md: "row" },
                    alignItems: { md: "center" },
                    gap: 2.5,
                  }}
                >
                  <Box>{icon}</Box>
                  <Box sx={{ textTransform: "none" }}>
                    <Typography
                      color="text.primary"
                      variant="body2"
                      fontWeight="bold"
                    >
                      {title}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      sx={{ my: 0.5 }}
                    >
                      {description}
                    </Typography>
                    <Link
                      color="primary"
                      variant="body2"
                      fontWeight="bold"
                      sx={{
                        display: "inline-flex",
                        alignItems: "center",
                        "& > svg": { transition: "0.2s" },
                        "&:hover > svg": { transform: "translateX(2px)" },
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <span>Learn more</span>
                      <ChevronRightRoundedIcon
                        fontSize="small"
                        sx={{ mt: "1px", ml: "2px" }}
                      />
                    </Link>
                    <Slider
                      sx={{ mt: 5 }}
                      size="small"
                      value={discount || 0}
                      onChange={handleSliderChange}
                      aria-labelledby="input-slider"
                      marks
                      step={1}
                      min={0}
                      max={20}
                      valueLabelDisplay="on"
                      getAriaValueText={valuetext}
                    />
                  </Box>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { sm: "flex" }, width: "100%" }}
          //   sx={{ display: { xs: "none", sm: "flex" }, width: "100%" }}
        >
          <Card
            variant="outlined"
            sx={{
              height: "100%",
              width: "100%",
              display: { sm: "flex" },
              // display: { xs: "none", sm: "flex" },
              pointerEvents: "none",
            }}
          >
            <Box
              sx={{
                m: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // width: 420,
                // height: 500,
                backgroundSize: "contain",
              }}
            >
              <div>
                <Typography component="h2" variant="h3" color="text.primary">
                  {discount || 0}%
                </Typography>
              </div>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
