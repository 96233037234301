import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Card,
  Collapse,
  CardHeader,
  CardActions,
  CardContent,
  Skeleton,
} from "@mui/material";
import { red } from "@mui/material/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import EditableTypewriterInput from "../../../../components/EditableTypewriterInput";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ReviewCard = ({
  headerTitle,
  content,
  loading = false,
  onChange,
  isActive = true,
  toggle,
}) => {
  const [expanded, setExpanded] = useState();
  const [removed, setRemoved] = useState(!isActive);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      {loading ? (
        <Skeleton variant="rounded" height={90} />
      ) : (
        <Card
          sx={{
            border: removed ? "2px solid" : undefined,
            borderColor: removed ? red[500] : undefined,
          }}
        >
          <CardHeader
            title={headerTitle}
            action={
              removed ? (
                <IconButton
                  color="success"
                  onClick={() => {
                    toggle(true);
                    setRemoved(false);
                    setExpanded(true);
                  }}
                >
                  <RestoreFromTrashIcon />
                </IconButton>
              ) : (
                <IconButton
                  color="error"
                  onClick={() => {
                    toggle(false);
                    setRemoved(true);
                    setExpanded(false);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )
            }
          />
          <CardActions disableSpacing>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit sx={{ ml: 1 }}>
            <CardContent>
              <EditableTypewriterInput
                style={{ width: "95%" }}
                multiline
                // error={industryValues.errors?.description}
                value={content}
                fullWidth
                onChange={onChange}
              />
            </CardContent>
          </Collapse>
        </Card>
      )}
    </>
  );
};

export default ReviewCard;
