import { CompanyFields } from ".";
import { isEpmty } from "../../../../../helper/TextHelper";

export const updateValues = (name, value, setState) => {
  setState((prevValues) => ({
    ...prevValues,
    company: {
      ...prevValues.company,
      [name]: value,
    },
  }));
};

export const handleInputChanges = (e, setState) => {
  const { name, value } = e.target;
  updateValues(name, value, setState);
};

export const validate = (values) => {
  const errors = {};
  const data = values.company;
  CompanyFields.forEach(({ name, required }) => {
    if (required && isEpmty(data[name])) {
      errors[name] = "This field is required.";
    }
  });

  // Add errors to data object
  data.errors = errors;

  // You can add more validation checks here based on your requirements

  // Optionally, you can log data with errors
  console.log("company data with errors:", data);

  return Object.keys(errors).length === 0;
};

export const createObjectFromFields = () => {
  const obj = {};

  CompanyFields.forEach(({ name, defaultValue }) => {
    obj[name] = defaultValue;
  });

  return obj;
};

export const generateCards = (jsonList) => {
  const cards = [];
  jsonList.forEach((item) => {
    const company = item.company;
    // Join products with a comma in the description
    const products = item.products.join(", ");
    const newCard = { title: company, description: `Products: ${products}` };
    cards.push(newCard);
  });

  return cards;
};
