import React from "react";
import {
  Checkbox,
  FormControlLabel,
  FormControl,
  FormHelperText,
  FormGroup,
} from "@mui/material";

const GroupedCheckbox = ({
  items,
  selectedItems,
  requiredSelectionItems = 0,
  onSelectionChange,
}) => {
  const error = selectedItems.filter((v) => v).length < requiredSelectionItems;

  const handleCheckboxChange = (item) => {
    const newCheckedItems = [...selectedItems];
    if (newCheckedItems.some((i) => i === item)) {
      newCheckedItems.splice(
        newCheckedItems.findIndex((i) => i === item),
        1
      );
    } else {
      newCheckedItems.push(item);
    }
    onSelectionChange(newCheckedItems);
  };

  return (
    <FormControl
      required
      error={error}
      component="fieldset"
      sx={{ m: 3 }}
      variant="standard"
    >
      {error ? (
        <FormHelperText>At least one selection is required.</FormHelperText>
      ) : null}
      <FormGroup>
        {items.map((item, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={selectedItems.some((i) => i === item)}
                onChange={() => handleCheckboxChange(item)}
              />
            }
            label={item}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

export default GroupedCheckbox;
