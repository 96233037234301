import { httpClient } from "./RestService";

export const getAll = async () => {
  return await httpClient().get("/v1/education/inst/list");
};

export const create = async (data) => {
  return await httpClient().post("/v1/education/uinst", data);
};

export const update = async (id, data) => {
  return await httpClient().put("/v1/education/uinst/" + id, data);
};

export const del = async (id) => {
  return await httpClient().delete("/v1/education/uinst/" + id);
};

export const getUserInstitutions = async () => {
  return await httpClient().get("/v1/education/uinst/list");
};

export const verify = async (id) => {
  return await httpClient().put("/v1/education/auth/uinst/verify/" + id);
};
