import React, { useState, useEffect } from "react";
import { Container, Grid, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as institutionService from "../../services/InstitutionService";
import UserInstitutionCard from "./components/UserInstitutionCard";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import Notification from "../../../../components/mui/Notification";
import NewCard from "../../../../components/NewCard";
import PageHeader from "../../../../components/PageHeader";
import {
  INSTRUCTOR_COURSE_LIST_ROUTE,
  INSTRUCTOR_INSTITUTION_UPSERT_ROUTE,
} from "../../helper/Constants";

export default function List() {
  const navigate = useNavigate();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [userInstitutions, setUserInstitutions] = useState([]);

  useEffect(() => {
    institutionService
      .getUserInstitutions()
      .then((response) => {
        setUserInstitutions(response.data);
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not connect to server. Please try again later.",
          type: "error",
        });
      })
      .finally(() => setLoading(false));
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    institutionService
      .del(id)
      .then(() => {
        const newList = userInstitutions.filter((item) => item.id !== id);
        setUserInstitutions(newList);
        setNotify({
          isOpen: true,
          message: "Deleted Successfully",
          type: "error",
        });
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not delete an institution. Please try again later.",
          type: "error",
        });
      });
  };

  const handleDelete = (id) => {
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this record?",
      subTitle: "You can't undo this operation",
      onConfirm: () => {
        onDelete(id);
      },
    });
  };

  const resendEmail = (id, email) => {
    institutionService
      .update(id, { email })
      .then(() => {
        setNotify({
          isOpen: true,
          message: "Successfully sent an email. Please verify!",
          type: "success",
        });
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not delete an institution. Please try again later.",
          type: "error",
        });
      });
  };

  const handleNew = () => {
    navigate(INSTRUCTOR_INSTITUTION_UPSERT_ROUTE);
  };
  const handleCourseList = (institutionId) => {
    navigate(INSTRUCTOR_COURSE_LIST_ROUTE, {
      state: { institutionId },
    });
  };

  const onRefresh = () => {
    setRefresh(!refresh);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <PageHeader title="List of Institutions" onRefresh={() => onRefresh()} />
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <NewCard title={"New Institution"} onclick={handleNew} />
        </Grid>
        {loading
          ? Array.from(new Array(3)).map((item, index) => (
              <Grid
                key={index}
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                sx={{ m: 2 }}
              >
                <Skeleton variant="rectangular" height={118} />
                <Skeleton />
                <Skeleton width="60%" />
              </Grid>
            ))
          : userInstitutions.map((userInstitution, index) => (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <UserInstitutionCard
                  userInstitution={userInstitution}
                  resendEmail={(email) =>
                    resendEmail(userInstitution.id, email)
                  }
                  handleDelete={() => handleDelete(userInstitution.id)}
                  handleCourseList={() =>
                    handleCourseList(userInstitution.institutionId)
                  }
                />
              </Grid>
            ))}
      </Grid>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Container>
  );
}
