import { courseFields } from ".";
import { isEpmty } from "../../../../../helper/TextHelper";
import { formatDate } from "../../../../../helper/DateHelper";

export const validate = (values) => {
  const errors = {};
  const data = values;
  courseFields.forEach(({ name, required }) => {
    if (required && isEpmty(data[name])) {
      errors[name] = "This field is required.";
    }
  });

  const authorsField = courseFields.find(field => field.name === "authors").defaultValue;
  if (data["authors"].includes(authorsField[0])) {
    errors["authors"] = "This field is required.";
  }

  // Add errors to data object
  data.errors = errors;

  // You can add more validation checks here based on your requirements

  // Optionally, you can log data with errors
  console.log("course data with errors:", data);

  return Object.keys(errors).length === 0;
};

export const formatDates = (values) => {
  if (values["startDate"]) {
    values["startDate"] = formatDate(values["startDate"]);
  }
  if (values["endDate"]) {
    values["endDate"] = formatDate(values["endDate"]);
  }
};
