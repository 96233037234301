import { SubTopicsFields } from ".";
import { isEpmty } from "../../../../../helper/TextHelper";

export const updateValues = (name, value, setState) => {
  setState((prevValues) => ({
    ...prevValues,
    subTopics: {
      ...prevValues.subTopics,
      [name]: value,
    },
  }));
};

export const handleInputChanges = (e, setState) => {
  const { name, value } = e.target;
  updateValues(name, value, setState);
};

export const validate = (values) => {
    const errors = {};
    const data = values.subTopics
    SubTopicsFields.forEach(({ name, required }) => {
      if (required && isEpmty(data[name])) {
        errors[name] = "This field is required.";
      }
    });

    if (data["list"]?.length > 2) {
      errors["list"] = "Maximum number of SubTopics is 2";
    }
  
    // Add errors to data object
    data.errors = errors;
  
    // You can add more validation checks here based on your requirements
  
    // Optionally, you can log data with errors
    console.log("SubTopics data with errors:", data);
  
    return Object.keys(errors).length === 0;
  };
  

export const createObjectFromFields = () => {
  const obj = {};

  SubTopicsFields.forEach(({ name, defaultValue }) => {
    obj[name] = defaultValue;
  });

  return obj;
};
