import initialState from "./initials";
import * as types from "./types";

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        userInfo: action.data
      };
    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    case types.PROFILE_UPDATED:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          firstName: action.data.firstName,
          lastName: action.data.lastName,
          photoUrl: action.data.photoUrl,
        },
      };
    default:
      return state;
  }
}
