import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Container, Typography, Button, Paper, Grid } from "@mui/material";

export const Completed = ({ onReset }) => {
  const navigate = useNavigate();
  return (
    <Container
      maxWidth="lg"
      sx={{
        mt: 4,
        mb: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography sx={{ mt: 2, mb: 1, textAlign: "center" }}>
              Successfully created a caseStudy - you&apos;ll recieve an email
              shortly!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                pt: 2,
                justifyContent: "center",
              }}
            >
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={onReset} color="success">New case study</Button>
              <Button onClick={() => navigate(-1, { replace: true })}>Back</Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};
