import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { MuiInput, MuiDatePicker, MuiButton } from "../../../../components";
import { useForm, Form } from "../../../../components/mui/useForm";
import { useLocation, useNavigate } from "react-router-dom";
import Notification from "../../../../components/mui/Notification";
import LoadingButton from "@mui/lab/LoadingButton";
import PageHeader from "../../../../components/PageHeader";
import Promotion from "./Promotion";
import * as assignmentService from "../../services/AssignmentService";
import { formatDate } from "../../../../helper/DateHelper";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import AutoCompleteAsync from "../../../../components/AutoCompleteAsync";

export default function New() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState();
  const [questions, setQuestions] = useState([]);
  const [questionError, setQuestionError] = useState({});
  const [discount, setDiscount] = useState(0);
  const casestudy = state.casestudy;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if (!values.hasOwnProperty("title") || "title" in fieldValues)
      temp.title = fieldValues.title ? "" : "This field is required.";
    if ("dueDate" in fieldValues)
      temp.dueDate = fieldValues.dueDate ? "" : "This field is required.";
    if ("type" in fieldValues) {
      temp.type = fieldValues.type ? "" : "This field is required.";
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  // Function to validate the criteria weights for all questions
  const validateQ = () => {
    const newQuestionErrors = {};

    questions.forEach((question) => {
      const totalWeight = question.criteria.reduce((sum, criterion) => {
        return sum + parseFloat(criterion.weight || 0);
      }, 0);

      // If total weight is not equal to 100, store the error message for this question
      if (totalWeight !== 100) {
        newQuestionErrors[
          question.id
        ] = `The total weight must equal 100%. Current total is ${totalWeight}%.`;
      }
    });

    // Update the error state with any found errors
    setQuestionError(newQuestionErrors);

    // Return true if no errors, otherwise false
    return Object.keys(newQuestionErrors).length === 0;
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm({ title: "", dueDate: "", type: "" }, true, validate);

  useEffect(() => {
    assignmentService
      .getAllQuestions(casestudy.id)
      .then((response) => {
        setQuestions(response.data.questions);
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not connect to server. Please try again later.",
          type: "error",
        });
      })
      .finally(() => setLoading(false));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate(values) && validateQ()) {
      setSubmitting(true);
      const request = {
        caseStudyId: casestudy.id,
        title: values.title,
        type: values.type,
        dueDate: formatDate(values.dueDate),
        discount: discount,
        questions: questions,
      };
      assignmentService
        .create(request)
        .then((response) => {
          resetForm();
          setDiscount(0);
          setNotify({
            isOpen: true,
            message: "Submitted Successfully",
            type: "success",
          });
          setTimeout(() => {
            // Add your logic for the transition
            navigate(-1, { replace: true });
          }, 2000);
        })
        .catch((e) => {
          console.error(`Error: ${e.message}`);
          setNotify({
            isOpen: true,
            message: "Could not create an assignment. Please try again later.",
            type: "error",
          });
        })
        .finally(() => setSubmitting(false));
    }
  };

  const handleCriteria = (questionId, criterionId, e) => {
    const { value } = e.target;

    // Update the weight of the specific criterion for the matching question
    const updatedQuestions = questions.map((question) => {
      if (question.id === questionId) {
        return {
          ...question,
          criteria: question.criteria.map((criterion) => {
            if (criterion.id === criterionId) {
              return {
                ...criterion,
                weight: value, // Update the weight with the new value
              };
            }
            return criterion;
          }),
        };
      }
      return question;
    });

    setQuestions(updatedQuestions); // Update the state with the new questions
  };

  const onTypeChange = (event, type) => {
    type = type ?? ""; // Set type to an empty string if it's null or undefined
    setValues({ ...values, type: type.id || "" }); // Handle case where type may not have an id
    validate({ type: type.id || "" });
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <PageHeader title={"New Project"} isChild={true} />
      <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <React.Fragment>
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={10} md={9}>
                <MuiInput
                  id="title"
                  name="title"
                  label="Title"
                  value={values.title}
                  onChange={(e) => handleInputChange(e)}
                  error={errors?.title}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <AutoCompleteAsync
                  name="type"
                  error={errors.type}
                  label={"Type"}
                  getOptions={() => assignmentService.types()}
                  onChange={onTypeChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <MuiDatePicker
                  minDate={new Date()}
                  openTo="year"
                  views={["year", "month", "day"]}
                  name="dueDate"
                  label="Due Date"
                  value={values.dueDate}
                  onChange={(e) => handleInputChange(e)}
                  error={errors?.dueDate}
                />
              </Grid>
            </Grid>
            {/* <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <MuiInput
                id="title"
                name="title"
                label="Title"
                value={values.title}
                onChange={(e) => handleInputChange(e)}
                error={errors?.title}
              />
              <MuiDatePicker
                minDate={new Date()}
                openTo="year"
                views={["year", "month", "day"]}
                name="dueDate"
                label="Due Date"
                value={values.dueDate}
                onChange={(e) => handleInputChange(e)}
                error={errors?.dueDate}
              />
            </Stack> */}
            <Promotion discount={discount} setDiscount={setDiscount} />
            {loading ? (
              <LoadingSpinner />
            ) : (
              <Box sx={{ m: 3 }}>
                <Typography variant="h4">Evaluation Criteria</Typography>
                {questions.map((question, qIndex) => (
                  <Card
                    key={qIndex}
                    sx={{
                      m: 2,
                      border: questionError[question.id]
                        ? "2px solid red"
                        : "none",
                    }}
                  >
                    <CardHeader
                      title={
                        <Typography variant="h5">
                          {"Question " + (qIndex + 1)}
                        </Typography>
                      }
                      subheader={
                        <Typography color="error">
                          {questionError[question.id]}
                        </Typography>
                      }
                    />
                    <CardContent>
                      <Grid container spacing={1}>
                        <Typography sx={{ ml: 2, mb: 2 }}>
                          {question.title}
                        </Typography>
                        {question?.criteria.map((criterion, index) => (
                          <Grid item sm={6} md={4} key={index}>
                            <TextField
                              name={criterion.title}
                              label={criterion.title}
                              type="number"
                              value={criterion.weight}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) =>
                                handleCriteria(question.id, criterion.id, e)
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    %
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </CardContent>
                  </Card>
                ))}
              </Box>
            )}

            <Stack
              spacing={{ xs: 1, sm: 2 }}
              direction={{ xs: "column", sm: "row" }}
              sx={{ ml: 1 }}
            >
              <LoadingButton
                type="submit"
                color="success"
                variant="outlined"
                loading={submitting}
              >
                Submit
              </LoadingButton>
              <MuiButton
                text="Cancel"
                color="info"
                variant="outlined"
                onClick={() => {
                  navigate(-1);
                }}
              />
            </Stack>
          </Form>
        </React.Fragment>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
    </Container>
  );
}
