import React, { useState, useEffect, useContext } from "react";
import { Container, Grid, Skeleton, useMediaQuery } from "@mui/material";
import * as casestudyService from "../../services/CasestudyService";
import Notification from "../../../../components/mui/Notification";
import PageHeader from "../../../../components/PageHeader";
import PurchaseCard from "./PurchaseCard";
import { AppContext } from "../../App";
import useRequireLogin from "../../../../common/useRequireLogin";
import { LOGIN_ROUTE } from "../../helper/Constants";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { CASE_STUDY_PURCHASE_CHECKOUT_ROUTE } from "../../helper/Constants";

export default function PurchaseByState() {
  const navigate = useNavigate();
  const [readyToLoad, setReadyToLoad] = useState(false);
  const { state } = useLocation();
  const caseStudyId = state.caseStudyId;
  const { appState } = useContext(AppContext);
  useRequireLogin(appState, LOGIN_ROUTE, "", () => setReadyToLoad(true));
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [loading, setLoading] = useState(true);
  const [caseStudy, setCaseStudy] = useState();
  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("sm")); // Adjust the breakpoint as needed

  useEffect(() => {
    if (readyToLoad) {
      if (!caseStudyId) {
        console.error(`Could not find Case study Id from url`);
        setNotify({
          isOpen: true,
          message:
            "Could not get id from URL. Please close this window and click on the shared link again.",
          type: "error",
        });
      } else {
        casestudyService
          .getPurchaseDetails(caseStudyId)
          .then((response) => {
            setCaseStudy(response.data);
            setLoading(false);
          })
          .catch((e) => {
            console.error(`Error: ${e.message}`);
            if (e.response && e.response.status === 404) {
              setNotify({
                isOpen: true,
                message: "Could not find purchase details.",
                type: "warning",
              });
            } else {
              setNotify({
                isOpen: true,
                message: "Could not connect to server. Please try again later.",
                type: "error",
              });
            }
          });
      }
    }
  }, [readyToLoad]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCheckout = () => {
    navigate(CASE_STUDY_PURCHASE_CHECKOUT_ROUTE, {
      state: {
        caseStudyId,
        amount: caseStudy.price,
        currency: caseStudy.currencyCode,
      },
    });
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <PageHeader title={"Case Study Details"} isChild={false} />
      <Grid container spacing={2} justifyContent="center">
        {loading ? (
          Array.from(new Array(1)).map((item, index) => (
            <Grid item key={index} xs={12} sm={6} sx={{ mt: 2 }}>
              <Skeleton variant="rectangular" height={118} />
              <Skeleton />
              <Skeleton />
            </Grid>
          ))
        ) : (
          <Grid item xs={12} sm={isSmOrDown ? 10 : 6} md={4}>
            <PurchaseCard
              caseStudy={caseStudy}
              handleCheckout={() => handleCheckout()}
            />
          </Grid>
        )}
      </Grid>
      <Notification notify={notify} setNotify={setNotify} />
    </Container>
  );
}
