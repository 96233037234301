import React, { useState } from "react";
import { Container, Grid, Paper, Typography } from "@mui/material";
import { MuiChipsInput } from "mui-chips-input";
import { AnimatedCard } from "../AnimatedCard";
import { MuiInput } from "../../../../../components";
import * as casestudyService from "../../../services/CasestudyService";
import Notification from "../../../../../components/mui/Notification";
import { isEpmty } from "../../../../../helper/TextHelper";
import Zoom from "@mui/material/Zoom";
import AssistantIcon from "@mui/icons-material/AutoAwesome";
import { LoadingButton } from "@mui/lab";

export const Page = ({ course, formValues, onInputChange, updateValues }) => {
  const industryValues = formValues.industry;
  const [typesLoading, setTypesLoading] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const caseStudyId = formValues.caseStudyId;

  const getIndustryTypes = () => {
    setTypesLoading(true);
    casestudyService
      .getIndustryTypes(
        caseStudyId,
        course.field,
        industryValues.country,
        industryValues.province
      )
      .then((res) => {
        updateValues("cards", res.data);
        updateValues("hiddenCards", res.data);
      })
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message: "Could not fetch industry types. Please try again later",
          type: "error",
        });
      })
      .finally(() => {
        setTypesLoading(false);
      });
  };

  const onChipsChange = (newChips) => {
    if (newChips.length > 0) {
      const selectedChip = newChips[newChips.length - 1];
      updateValues("types", [selectedChip]);
      if (industryValues.cards?.some((card) => card.title !== selectedChip)) {
        // seems user entered a value manually, reseting the hiddenCards
        updateValues("hiddenCards", industryValues.cards);
      }
    }
  };

  const handleCardClick = (card) => {
    const filteredCards = industryValues.cards.filter(
      (crd) => crd.title !== card.title
    );
    updateValues("types", [card.title]);
    updateValues("hiddenCards", filteredCards);
    if (industryValues.errors) {
      updateValues(
        "errors",
        Object.assign(
          {},
          industryValues.errors,
          delete industryValues.errors.types
        )
      );
    }
  };

  const handleDeleteChip = (chipValue, chipIndex) => {
    updateValues("types", []);

    // Check if chipValue exists in cards and not already in hiddenCards
    const existingCard = industryValues.cards.find(
      (card) => card.title === chipValue
    );

    const isCardHidden = industryValues.hiddenCards.some(
      (hiddenCard) => hiddenCard.title === chipValue
    );

    if (existingCard && !isCardHidden) {
      industryValues.hiddenCards.push(existingCard);
    }
  };

  const handleInputChanges = (e) => {
    const { value } = e.target;
    if (isEpmty(value)) {
      updateValues("cards", []);
      updateValues("hiddenCards", []);
      updateValues("types", []);
    }
    onInputChange(e);
  };

  function hasCountry() {
    return !isEpmty(industryValues.country);
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <React.Fragment>
          <Typography sx={{ fontWeight: "bold" }} variant="h6" gutterBottom>
            Geographic
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <MuiInput
                required
                error={industryValues.errors?.country}
                id="country"
                name="country"
                label="Country"
                value={industryValues.country || ""}
                fullWidth
                // autoComplete="geo country"
                variant="standard"
                onChange={(e) => handleInputChanges(e)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MuiInput
                // required
                error={industryValues.errors?.province}
                id="province"
                name="province"
                label="State/Province"
                value={industryValues.province}
                fullWidth
                // autoComplete="geo province"
                variant="standard"
                onChange={(e) => handleInputChanges(e)}
              />
            </Grid>
          </Grid>
        </React.Fragment>
      </Paper>

      {hasCountry() && (
        <>
          <Zoom
            style={{ transitionDelay: "500ms" }}
            in={hasCountry()}
            timeout={500}
          >
            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
            >
              <Grid
                container
                spacing={0}
                justify="center"
                alignItems="center"
                sx={{ mb: 1 }}
              >
                <Grid item>
                  <Typography
                    sx={{ fontWeight: "bold" }}
                    variant="h6"
                    gutterBottom
                  >
                    Sector
                  </Typography>
                </Grid>
                <Grid item style={{ marginLeft: "auto" }}>
                  <LoadingButton
                    disabled={industryValues.cards?.length > 0}
                    sx={{ mr: 1 }}
                    size="small"
                    onClick={() => getIndustryTypes()}
                    loading={typesLoading}
                    variant="outlined"
                    startIcon={<AssistantIcon />}
                    loadingPosition="start"
                  >
                    <span>Assistant</span>
                  </LoadingButton>
                </Grid>

                <Grid item xs={12} sx={{ mt: 2 }}>
                  <MuiChipsInput
                    style={{ width: "95%" }}
                    disableEdition
                    hideClearAll
                    value={industryValues.types}
                    onDeleteChip={handleDeleteChip}
                    onChange={onChipsChange}
                    error={!isEpmty(industryValues.errors?.types)}
                    placeholder="Type and press enter OR select a sector"
                  />
                </Grid>
              </Grid>

              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {industryValues.hiddenCards?.map((card, index) => (
                  <AnimatedCard
                    key={index}
                    card={card}
                    index={index}
                    handleCardClick={handleCardClick}
                  />
                ))}
              </div>
            </Paper>
          </Zoom>
        </>
      )}

      <Notification
        notify={notify}
        setNotify={setNotify}
        autoHideDuration={4000}
      />
    </Container>
  );
};
