import * as types from './types';


// LOGOUT
export function logoutSuccess() {
	return {
		type: types.LOGOUT_SUCCESS,
	};
}

// LOGIN
export function loginSuccess(userInfo) {
	return {
		type: types.LOGIN_SUCCESS,
		data: userInfo
	};
}

// UPDATE PROFILE
export function profileUpdated(userInfo) {
	return {
		type: types.PROFILE_UPDATED,
		data: userInfo
	};
}
