export function isEmailDomainValid(email, domain) {
  // Split the email address by '@'
  const parts = email.split("@");

  // Check if there are two parts after splitting
  if (parts.length === 2) {
    return parts[1].toLowerCase() === domain;
  }

  return false; // Return false if there are not exactly two parts after splitting
}
