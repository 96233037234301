import React, { useState } from "react";
import {
  Typography,
  Paper,
  Grid,
  Container,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@mui/material";
import * as courseService from "../../../services/CourseService";
import { isEpmty } from "../../../../../helper/TextHelper";
import { MuiChipsInput } from "mui-chips-input";
import { TextBookChaptersAnimatedCard } from "../TextBookChaptersAnimatedCard";
import Notification from "../../../../../components/mui/Notification";
import { TextBookChapterTopicCard } from "../TextBookChapterTopicCard";
import { LoadingButton } from "@mui/lab";
import AssistantIcon from "@mui/icons-material/AutoAwesome";

export const Page = ({ course, formValues, onInputChange, updateValues }) => {
  const baseInfoValues = formValues.baseInfo;
  const [chaptersLoading, setChaptersLoading] = useState();
  const [currentChapter, setCurrentChapter] = useState();
  const [topicsLoading, setTopicsLoading] = useState();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const getTextbookChapters = () => {
    setChaptersLoading(true);
    courseService
      .getTextbookChapters(course.textBookId)
      .then((res) => {
        updateValues("chaptersCards", res.data);
        updateValues("chaptersHiddenCards", res.data);
      })
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message: "Could not fetch textbook chapters. Please try again later",
          type: "error",
        });
      })
      .finally(() => {
        setChaptersLoading(false);
      });
  };

  const getTextbookChapterTopics = (chapterId) => {
    setTopicsLoading(true);
    courseService
      .getTextbookChapterTopics(chapterId)
      .then((res) => {
        updateValues("chapterTopicsCards", res.data);
        updateValues("chapterTopicsHiddenCards", res.data);
      })
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message:
            "Could not fetch textbook chapter topics. Please try again later",
          type: "error",
        });
      })
      .finally(() => {
        setTopicsLoading(false);
      });
  };

  const handleChapterChipsChange = (newChips) => {
    if (newChips.length > 0) {
      const newChip = newChips[newChips.length - 1];
      updateValues("chapters", [newChip]);
      updateValues("chapterTopics", []);
      updateValues("chapterTopicsCards", []);
      updateValues("chapterTopicsHiddenCards", []);

      if (
        baseInfoValues.chaptersCards?.some((card) => card.title !== newChip)
      ) {
        updateValues("chaptersHiddenCards", baseInfoValues.chaptersCards);
      }
    }
  };

  const handleChapterTopicsChipsChange = (newChips) => {
    updateValues("chapterTopics", newChips);
  };

  const handleChaptersCardClick = (card) => {
    const filteredCards = baseInfoValues.chaptersCards.filter(
      (crd) => crd.id !== card.id
    );
    setCurrentChapter(card);
    updateValues("chapters", [card.title]);
    updateValues("chaptersHiddenCards", filteredCards);
    updateValues("chapterTopics", []);
    updateValues("chapterTopicsCards", []);
    updateValues("chapterTopicsHiddenCards", []);

    if (baseInfoValues.errors) {
      const targetObject = Object.assign({}, baseInfoValues.errors);
      targetObject.chapters = undefined;
      updateValues("errors", targetObject);
    }
  };

  const handleChapterTopicsCardClick = (card) => {
    const filteredCards = baseInfoValues.chapterTopicsHiddenCards.filter(
      (crd) => crd !== card
    );
    updateValues("chapterTopics", [...baseInfoValues.chapterTopics, card]);
    updateValues("chapterTopicsHiddenCards", filteredCards);

    if (baseInfoValues.errors) {
      const targetObject = Object.assign({}, baseInfoValues.errors);
      targetObject.chapterTopics = undefined;
      updateValues("errors", targetObject);
    }
  };

  const handleDeleteChaptersChip = (chipValue, chipIndex) => {
    setCurrentChapter(undefined);
    updateValues("chapters", []);
    updateValues("chapterTopics", []);
    updateValues("chapterTopicsCards", []);
    updateValues("chapterTopicsHiddenCards", []);

    // Check if chipValue exists in chaptersCards and not already in hiddenCards
    const existingCard = baseInfoValues.chaptersCards.find(
      (card) => card.title === chipValue
    );
    const isCardHidden = baseInfoValues.chaptersHiddenCards.some(
      (hiddenCard) => hiddenCard.title === chipValue
    );

    if (existingCard && !isCardHidden) {
      baseInfoValues.chaptersHiddenCards.push(existingCard);
    }
  };

  const handleDeleteChapterTopicsChip = (chipValue, chipIndex) => {
    const filteredChips = baseInfoValues.chapterTopics.filter(
      (chp) => chp !== chipValue
    );
    updateValues("chapterTopics", filteredChips);

    // Check if chipValue exists in chapterTopicsCards and not already in chapterTopicsHiddenCards
    const existingCard = baseInfoValues.chapterTopicsCards.find(
      (card) => card === chipValue
    );
    const isCardHidden =
      baseInfoValues.chapterTopicsHiddenCards.includes(chipValue);

    if (existingCard && !isCardHidden) {
      baseInfoValues.chapterTopicsHiddenCards.push(existingCard);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <Grid
          container
          spacing={0}
          justify="center"
          alignItems="center"
          sx={{ mb: 1 }}
        >
          <Grid item>
            <Typography sx={{ fontWeight: "bold" }} variant="h6" gutterBottom>
              Textbook Chapter
            </Typography>
          </Grid>
          <Grid item style={{ marginLeft: "auto" }}>
            <LoadingButton
              disabled={baseInfoValues.chaptersCards?.length > 0}
              sx={{ mr: 1 }}
              size="small"
              onClick={() => getTextbookChapters()}
              loading={chaptersLoading}
              variant="outlined"
              startIcon={<AssistantIcon />}
              loadingPosition="start"
            >
              <span>Assistant</span>
            </LoadingButton>
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <MuiChipsInput
              style={{ width: "95%" }}
              hideClearAll
              value={
                isEpmty(baseInfoValues.chapters)
                  ? []
                  : [baseInfoValues.chapters[0]]
              }
              onDeleteChip={handleDeleteChaptersChip}
              onChange={handleChapterChipsChange}
              error={!isEpmty(baseInfoValues.errors?.chapters)}
              // InputProps={{
              //   readOnly: true,
              // }}
              helperText={
                isEpmty(baseInfoValues.chapters)
                  ? ""
                  : "Double click to edit a chapter"
              }
              placeholder="Select a Chapter"
            />
          </Grid>
        </Grid>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {baseInfoValues.chaptersHiddenCards?.map((card, index) => (
            <TextBookChaptersAnimatedCard
              key={index}
              card={card}
              index={index}
              handleCardClick={handleChaptersCardClick}
            />
          ))}
        </div>
      </Paper>

      {!isEpmty(baseInfoValues.chapters) && (
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Grid
            container
            spacing={0}
            justify="center"
            alignItems="center"
            sx={{ mb: 1 }}
          >
            <Grid item>
              <Typography sx={{ fontWeight: "bold" }} variant="h6" gutterBottom>
                Textbook Chapter Topics
              </Typography>
            </Grid>
            {currentChapter && (
              <Grid item style={{ marginLeft: "auto" }}>
                <LoadingButton
                  disabled={baseInfoValues.chapterTopicsCards?.length > 0}
                  sx={{ mr: 1 }}
                  size="small"
                  onClick={() => getTextbookChapterTopics(currentChapter.id)}
                  loading={topicsLoading}
                  variant="outlined"
                  startIcon={<AssistantIcon />}
                  loadingPosition="start"
                >
                  <span>Assistant</span>
                </LoadingButton>
              </Grid>
            )}

            <Grid item xs={12} sx={{ mt: 2 }}>
              <MuiChipsInput
                style={{ width: "95%" }}
                hideClearAll
                value={baseInfoValues.chapterTopics}
                onDeleteChip={handleDeleteChapterTopicsChip}
                onChange={handleChapterTopicsChipsChange}
                error={!isEpmty(baseInfoValues.errors?.chapterTopics)}
                helperText={
                  isEpmty(baseInfoValues.chapterTopics)
                    ? ""
                    : "Double click to edit a topic"
                }
                placeholder="Select a Topic"
              />
            </Grid>
          </Grid>

          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {baseInfoValues.chapterTopicsHiddenCards?.map((card, index) => (
              <TextBookChapterTopicCard
                key={index}
                card={card}
                index={index}
                handleCardClick={handleChapterTopicsCardClick}
              />
            ))}
          </div>
        </Paper>
      )}

      {/* <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <React.Fragment>
          <Typography sx={{ fontWeight: "bold" }} variant="h6" gutterBottom>
            Date
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <MuiDatePicker
                minDate={new Date()}
                openTo="year"
                views={["year", "month", "day"]}
                name="startDate"
                label="Start Date"
                value={baseInfoValues.startDate}
                onChange={(e) => onInputChange(e)}
                error={baseInfoValues.errors?.startDate}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MuiDatePicker
                minDate={new Date()}
                openTo="year"
                views={["year", "month", "day"]}
                name="dueDate"
                label="Due Date"
                value={baseInfoValues.dueDate}
                onChange={(e) => onInputChange(e)}
                error={baseInfoValues.errors?.dueDate}
              />
            </Grid>
          </Grid>
        </React.Fragment>
      </Paper> */}

      <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <React.Fragment>
          <Typography sx={{ fontWeight: "bold" }} variant="h5" gutterBottom>
            Market Type
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl
                required
                error={!isEpmty(baseInfoValues.errors?.marketType)}
              >
                <RadioGroup
                  aria-labelledby="market-type-radio-buttons-group"
                  // defaultValue="biz-market"
                  name="marketType"
                  value={baseInfoValues.marketType}
                  onChange={(e) => onInputChange(e)}
                >
                  <FormControlLabel
                    value="b2b"
                    control={<Radio />}
                    label="B2B Market"
                  />
                  <FormControlLabel
                    value="b2c"
                    control={<Radio />}
                    label="B2C Market"
                  />
                </RadioGroup>
                <FormHelperText>
                  {baseInfoValues.errors?.marketType}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </React.Fragment>
      </Paper>

      <Notification
        notify={notify}
        setNotify={setNotify}
        autoHideDuration={4000}
      />
    </Container>
  );
};
