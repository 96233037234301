import React from 'react'

const NotFound = () => {
    return (
      <>
        <h1>404 - Page Not Found</h1>
        <p>Sorry, the page you are looking for could not be found.</p>
      </>
    );
  };

  export default NotFound;
