import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// loginRoute is required as this hook might be used in multiple domains
const useRequireLogin = (appState, loginRoute, redirectRoute, loggedInCallback) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!appState.isLoggedIn) {
      navigate(loginRoute, { state: { redirect: redirectRoute } });
    } else {
      if (loggedInCallback) loggedInCallback(); // Call the callback function to signal user is logged in
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useRequireLogin;
