import React, { useState } from 'react'
import { Box, Button, FormControl, FormHelperText } from '@mui/material';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))


export default function VideoUpload(props) {

    const classes = useStyles();
    const [disabled] = useState(false)
    const { label, error = null, onFileSelected, acceptType } = props;

    return (
        <FormControl {...(error && {error:true})}
            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
                <Box component="span" className={classes.root}
                sx={{
                    border: 0,
                    borderRadius: 1,
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                    paddingY: 1,
                    paddingX: 1,
                    '&:hover': {
                        borderColor: disabled ? undefined : 'text.primary',
                    },
                    '&:focus-within': {
                        borderColor: 'primary.main',
                        borderWidth: 0,
                    },
                }}>
                    <label htmlFor="btn-upload">
                        <input
                            id="btn-upload"
                            name="btn-upload"
                            style={{ display: 'none' }}
                            type="file"
                            accept={acceptType}
                            onChange={onFileSelected} />
                        <Button
                            className="btn-choose"
                            variant="outlined"
                            component="span" >
                            {label}
                        </Button>
                    </label>
                </Box>
                {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    )
}